<template>
  <div class="main">
    <div class="banner">
      <div class="banner-img">
        <img
          :src="imgs.banner"
          alt="谋杀之谜秘书 - 剧本杀 - 店铺经营小秘书、为剧本杀商家而设计"
        />
      </div>
      <div class="container">
        <div class="banner-inner d-flex">
          <div class="d-flex flex-column pt-5 pb-3 pl-3 w-60">
            <div class="topTip">
              <img
                :src="imgs.topTip"
                alt="谋杀之谜秘书 - 剧本杀 - 店铺经营小秘书、为剧本杀商家而设计"
              />
            </div>
            <div class="joinText">
              <img
                :src="imgs.joinText"
                alt="谋杀之谜秘书 - 剧本杀 - 店铺经营小秘书、为剧本杀商家而设计"
              />
              <div class="arrow">
                <img
                  :src="imgs.arrow"
                  alt="谋杀之谜秘书 - 剧本杀 - 店铺经营小秘书、为剧本杀商家而设计"
                />
              </div>
            </div>
            <div class="banner-title">
              店铺经营小秘书、为剧本杀商家而设计、预约组局
              活动抽奖，会员储值营销卡券、剧本目录开本资源...
            </div>
            <div class="w-100 d-flex justify-content-end">
              <div class="bottomTip">
                <!-- <img :src="imgs.bottomTip" alt="谋杀之谜秘书 - 剧本杀 - 店铺经营小秘书、为剧本杀商家而设计" /> -->
                / MOUSHAZHIMIMISHU /
              </div>
            </div>
          </div>
          <div class="phone">
            <img
              :src="imgs.phone"
              alt="谋杀之谜秘书 - 剧本杀 - 店铺经营小秘书、为剧本杀商家而设计"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="join-method mb-3">
        <div class="d-flex justify-content-between mb-3">
          <div class="joinTitile">
            <img
              :src="imgs.joinTitile"
              alt="谋杀之谜秘书 - 剧本杀 - 店铺经营小秘书、为剧本杀商家而设计"
            />
          </div>
          <div class="viewD" @click="goPage">
            <img
              :src="imgs.viewD"
              alt="谋杀之谜秘书 - 剧本杀 - 店铺经营小秘书、为剧本杀商家而设计"
            />
          </div>
        </div>
        <div class="shell">
          <div class="join-step-box">
            <div class="join-step row">
              <div class="shopper col-12 col-sm-12 col-md-6">
                <div class="title mb-3">商家注册:</div>
                <div class="d-flex step mb-3">
                  <div>① 店铺logo/头像</div>
                  <div>② 营业执照</div>
                </div>
                <div class="d-flex step">
                  <div>③ 法人身份证人像</div>
                  <div>④ 门店照片</div>
                </div>
              </div>
              <div class="issue col-12 col-sm-12 col-md-6">
                <div class="title mb-3">发行注册:</div>
                <div class="d-flex step mb-3">
                  <div>① 店铺logo/头像</div>
                  <div>② 营业执照</div>
                </div>
                <div class="d-flex step">
                  <div>③ 法人身份证人像</div>
                  <div>④ 工作室LOGO</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="wechat">
          <div class="wechat-title">请扫码关注公众号/联系客服进行注册</div>
          <div class="wechat-box row">
            <div class="col-12 col-sm-12 col-md-4">
              <div>
                <img
                  :src="imgs.dagou"
                  alt="谋杀之谜秘书 - 剧本杀 - 店铺经营小秘书、为剧本杀商家而设计"
                />
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-4">
              <div>
                <img
                  :src="imgs.publicImg"
                  alt="谋杀之谜秘书 - 剧本杀 - 店铺经营小秘书、为剧本杀商家而设计"
                />
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-4">
              <div>
                <img
                  :src="imgs.miniPubImg"
                  alt="谋杀之谜秘书 - 剧本杀 - 店铺经营小秘书、为剧本杀商家而设计"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      imgs: {
        banner: require("@/assets/images/join/banner.png"),
        topTip: require("@/assets/images/join/topTip.png"),
        joinText: require("@/assets/images/join/joinText.png"),
        bottomTip: require("@/assets/images/join/bottomTip.png"),
        phone: require("@/assets/images/join/phone.png"),
        arrow: require("@/assets/images/join/arrow.png"),
        joinTitile: require("@/assets/images/join/joinTitile.png"),
        viewD: require("@/assets/images/join/viewD.png"),
        dagou: require("@/assets/images/dagou.png"),
        publicImg: require("@/assets/images/publicImg.png"),
        miniPubImg: require("@/assets/images/miniPubImg.png"),
        contactImg: require("@/assets/images/join/contact.png"),
      },
    };
  },
  methods: {
    goPage() {
      this.$router.push({ name: "settled" });
      this.$store.commit("SET_ACTIVE", 1);
    },
  },
};
</script>
<style scoped>
.w-60 {
  width: 60%;
}
.banner {
  width: 100%;
  position: relative;
  padding-top: 10rem;
  margin-bottom: 5rem;
}
.banner-img {
  width: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
}
img {
  width: 100%;
}
.banner-inner {
  width: 100%;
  align-items: center;
}
.banner-inner .topTip {
  width: 35%;
  margin-bottom: 8rem;
}
.banner-inner .joinText {
  width: 70%;
  margin-bottom: 5rem;
  position: relative;
  margin-left: 5rem;
}
.banner-inner .joinText .arrow {
  width: 15%;
  position: absolute;
  right: 0;
  bottom: -5rem;
}
.banner-inner .banner-title {
  width: 70%;
  background-color: #40445d;
  border-radius: 20px;
  padding: 1rem 2rem;
  color: #fff;
  line-height: 1.8rem;
  margin-top: 4rem;
  margin-bottom: 6rem;
  margin-left: 3rem;
}
.banner-inner .bottomTip {
  width: 30%;
  font-weight: 600;
  font-size: 0.8rem;
  font-style: italic;
  color: rgb(72, 70, 85);
}
.phone {
  width: 40%;
}
.join-method {
  width: 100%;
  margin-top: 200px;
}
.join-method .joinTitile {
  width: 20%;
}
.join-method .viewD {
  width: 15%;
}
.join-method .shell {
  width: 100%;
  display: flex;
  transform: scale(0.8);
}
.join-method .shell::before {
  display: block;
  content: "需要您提前准备的资料";
  width: 3.5rem;
  background-color: #554f5e;
  color: #fff;
  padding: 1rem;
  line-height: 24px;
  font-size: 1.3rem;
  margin-right: 0.8rem;
  font-weight: 600;
}
.join-method .join-step-box {
  display: flex;
  width: 100%;
  justify-content: center;
  align-content: center;
  background-image: url(../../assets/images/join/kuang.png);
  background-size: contain;
  background-repeat: no-repeat;
  padding: 1rem 1rem 1.5rem 1rem;
}

.join-method .join-step {
  width: 100%;
}
.join-method .join-step > div {
  width: 50%;
  text-align: center;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.join-method .join-step .issue {
  background: linear-gradient(236deg, #e49c9c 0%, #554f5e 100%);
  color: #fff;
}
.join-method .join-step .issue .title {
  color: #fff;
}
.join-method .join-step > div .title {
  font-size: 1.8rem;
  font-weight: 600;
  font-style: italic;
  color: #3e3d4e;
}
.join-method .join-step > div .step {
  font-size: 1.3rem;
}
.join-method .join-step > div .step div {
  width: 50%;
}
.join-method .wechat {
  width: 100%;
}
.join-method .wechat .wechat-title {
  width: 100%;
  text-align: center;
  font-size: 1.6rem;
  font-family: Microsoft YaHei;
  font-weight: 700;
  color: #3e3d4e;
  line-height: 13px;
  margin-top: 6rem;
  margin-bottom: 6rem;
}
.join-method .wechat .wechat-box {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  transform: scale(.7);
}
.join-method .wechat .wechat-box > div > div {
  width: 70%;
  padding: 1.5rem;
  border: 2px solid #554f5e;
  border-radius: 1rem;
  position: relative;
  margin: 0 auto;
}
.join-method .wechat .wechat-box > div > div::before {
  position: absolute;
  display: block;
  content: "秘书小程序";
  width: 3.5rem;
  background-color: #554f5e;
  color: #fff;
  padding: 1rem;
  line-height: 24px;
  font-size: 1.3rem;
  margin-right: 0.8rem;
  font-weight: 600;
  left: -3.5rem;
  top: 50%;
  transform: translateY(-50%);
}
.join-method .wechat .wechat-box > div:nth-child(1) > div::before {
  content: "小秘书大狗";
}
.join-method .wechat .wechat-box > div:nth-child(2) > div::before {
  content: "秘书公众号";
}

@media screen and (max-width: 500px) {
  .w-60{
    width: 100%;
  }
  .banner {
    padding-top: 4rem;
    margin-bottom: 2rem;
  }
  .banner .banner-inner {
    width: 100%;
    flex-direction: column;
  }
  .banner .banner-img img {
    width: 216%;
  }
  .banner .banner-inner > div {
    padding-top: 0 !important;
  }
  .banner-inner .bottomTip {
    width: auto;
  }
  .banner-inner .topTip {
    width: 50%;
    margin-bottom: 0;
  }
  .banner-inner .joinText {
    width: 80%;
    margin-bottom: 0;
    margin-left: 0;
    margin-bottom: 2rem;
  }
  .banner-inner .joinText .arrow {
    display: none;
  }
  .banner-inner .banner-title {
    width: 100%;
    margin: 0;
    padding: 1rem;
    font-size: 0.9rem;
  }
  .banner .phone {
    position: relative;
    width: 80%;
    margin: 0 auto;
    top: 0;
    right: 0;
    transform: initial;
  }
  .join-method .joinTitile {
    width: 50%;
  }
  .join-method .viewD {
    width: 30%;
  }
  .join-method .shell {
    flex-direction: column;
    transform: scale(.9);
  }
  .join-method .shell::before {
    width: 100%;
  }
  .join-method .join-step-box {
    background-image: none;
    border: 1px solid #554f5e;
    padding: 1rem;
  }
  .join-method .join-step > div .step > div {
    font-size: 1rem;
    padding: 0 !important;
  }
  .join-method .wechat {
    margin-top: 1rem;
  }
  .join-method .wechat .wechat-title {
    margin: 0 auto;
    width: 100%;
    font-size: 1rem;
    margin-top: 1rem;
    margin-bottom: 3rem;
  }
  .join-method {
    margin-bottom: 1rem;
    margin-top: 3rem;
  }
  .join-method .wechat .wechat-box {
    margin: 0;
    transform: scale(1);
  }
  .join-method .wechat .wechat-box > div {
    padding-left: 3rem;
    margin-bottom: 1rem;
  }
}
</style>
