<template>
  <div class="main" id="main">
    <div>
      <div>
        <img class="logo" src="../../../assets/images/logo.jpeg" />
      </div>
      <div @click="goUrl">谋杀之谜秘书</div>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "Home",
  data() {
    return {};
  },
  mounted() {},
  methods: {
    goUrl() {
      window.location.href = "https://wxaurl.cn/7MKMrijyGgr";
    },
  },
};
</script>
  <style scoped>
#main {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.logo {
  width: 10em;
  height: 10em;
  margin-bottom: 1em;
}
</style>
  