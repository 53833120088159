<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "vProblem",
  computed: {},
  mounted() {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
</style>