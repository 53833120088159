<template>
  <div class="main" id="main">
    <div class="banner">
      <div class="banner-img">
        <img :src="imgs.banner" alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计" />
      </div>

      <div class="container mb-3">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-6">
            <img :src="imgs.phone" alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计" />
          </div>
          <div class="hr col-12 col-sm-12 col-md-6">
            <div class="arrow-box w-100 d-flex justify-content-end mb-3">
              <div class="arrow">
                <img :src="imgs.header1" alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计" />
              </div>
            </div>
            <div class="title">
              <img :src="imgs.header2" alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计" />
              <div class="subtitle">
                开店经营小秘书、为 <span>剧本杀</span> 商家而设计
              </div>
            </div>
            <div class="shopnum">
              <div>服务全国12000+店铺 / 200+国内城市</div>
              <div>30+海外店铺 / 16个海外城市</div>
            </div>
            <div class="w-100 d-flex justify-content-end">
              <div class="sh">
                <div>累计服务用户600w+</div>
                <div>总收录剧本11000+</div>
              </div>
            </div>
            <div class="ele-box w-100 d-flex justify-content-end">
              <div class="ele">
                <img :src="imgs.header5" alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计" />
              </div>
            </div>
          </div>
        </div>
        <div class="mt-3 mini-title row justify-content-center align-items-end">
          <div class="col-9 col-sm-8 col-md-3">
            <img :src="imgs.funIntroL" alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计" />
          </div>
          <div class="col-9 col-sm-8 col-md-4">
            <img :src="imgs.funIntroR" alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计" />
          </div>
        </div>
      </div>
      <div class="fun-show" id="animateBox">
        <div v-for="(item, index) in maImgs" :key="index" @mouseover="aa(index)" @mouseleave="bb(index)"
          ref="img{{index}}">
          <img :src="item.maImg" alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计" />
        </div>
      </div>

      <div class="container">
        <div class="row mini-title flex-column align-items-center mb-3">
          <div class="col-8 col-sm-8 col-md-3">
            <img
              :src="imgs.spL"
              alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计"
            />
          </div>
          <div
            style="
              color: rgba(43, 47, 82, 0.5);
              font-weight: 600;
              margin-top: 0.5rem;
              margin-bottom: 1rem;
              font-style: italic;
            "
          >
            开店经营小秘书 为剧本杀商家而设计
          </div>
        </div>
        <div class="row serP">
          <div class="col-12 col-sm-12 col-md-6 service">
            <div class="d-flex justify-content-end">
              <div class="card-title">我们的服务：</div>
            </div>
            <div class="card-body">
              <div>
                /<strong>免费试用7天</strong>，无隐藏限制功能，满意再续费 /
              </div>
              <div>
                / <strong>永久免费更新</strong>，数据永久保存，过期后续费随时用
                /
              </div>
              <div>/ 7*18h专属客服，剧本上架、店铺设置喊客服效劳 /</div>
              <div>
                / 秘书扫码<strong>物料免费邮寄</strong>，扫码买单、扫码查看剧本
                目录扫码开局选剧本 /
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-6 price">
            <div class="d-flex justify-content-end">
              <div class="card-title">高 级 版：</div>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="d-flex align-items-baseline">
                  <div class="bigT">月 度/</div>
                  <div class="smallT">Monthly payment</div>
                </div>
                <div class="d-flex align-items-baseline">
                  <div class="smallP">￥</div>
                  <div class="bigP">78</div>
                </div>
              </div>

              <div class="d-flex justify-content-between">
                <div class="d-flex align-items-baseline">
                  <div class="bigT">季 度/</div>
                  <div class="smallT">Quarterly payment</div>
                </div>
                <div class="d-flex align-items-baseline">
                  <div class="smallP">￥</div>
                  <div class="bigP">198</div>
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <div class="d-flex align-items-baseline">
                  <div class="bigT">年 度/</div>
                  <div class="smallT">Annual payment</div>
                </div>
                <div class="d-flex align-items-baseline">
                  <div class="smallP">￥</div>
                  <div class="bigP">698</div>
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <div class="d-flex align-items-baseline">
                  <div class="bigT">永 久/</div>
                  <div class="smallT">perpetual</div>
                </div>
                <div class="d-flex align-items-baseline">
                  <div class="smallP">￥</div>
                  <div class="bigP">3800</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container" style="margin-top: 50px;">

        <div class="version-title">选择适合你的会员方案</div>
        <div class="version-subtitle">帮您拓客锁客、完成私域流量不断累积</div>

        <div class="centerY flex-column">
          <div class="version-item">
            <div class="flex-column centerZ mr-4">
              <div class="centerY flex-column">
                <img class="v-logo" src="https://image.jvbensha.com/shopImage/kqkPk-1684390658706.jpg" alt="">
                <img class="vip-img" :src="imgs.vip2" alt="">
              </div>
              <div>联系客服免费获取</div>
              <div class="mb-3"><span class="v-symbol">￥</span><span class="v-price">99</span><span>/年</span></div>
            </div>
            <div class="d-flex flex-wrap v-tag">
              <div>
                <svg class="svg-icon" style="--color: #999999;">
                  <use xlink:href="#check"></use>
                </svg>
                <img :src="imgs.dui" class="svg-icon" alt="">
                剧本目录
              </div>
              <div>
                <svg class="svg-icon" style="--color: #999999;">
                  <use xlink:href="#check"></use>
                </svg>
                <img :src="imgs.dui" class="svg-icon" alt="">
                预约组局
              </div>
              <div>
                <svg class="svg-icon" style="--color: #999999;">
                  <use xlink:href="#check"></use>
                </svg>
                <img :src="imgs.dui" class="svg-icon" alt="">
                开本资源
              </div>
              <div>
                <svg class="svg-icon" style="--color: #999999;">
                  <use xlink:href="#check"></use>
                </svg>
                <img :src="imgs.dui" class="svg-icon" alt="">
                DM展示
              </div>
              <div>
                <svg class="svg-icon" style="--color: #999999;">
                  <use xlink:href="#check"></use>
                </svg>
                <img :src="imgs.dui" class="svg-icon" alt="">
                店铺黑名单
              </div>
              <div>
                <svg class="svg-icon" style="--color: #999999;">
                  <use xlink:href="#check"></use>
                </svg>
                <img :src="imgs.dui" class="svg-icon" alt="">
                功能改进
              </div>
            </div>
          </div>
          <div class="version-item">
            <div class="flex-column centerZ mr-4">
              <div class="centerY flex-column">
                <img class="v-logo" src="https://image.jvbensha.com/shopImage/kqkPk-1684390658706.jpg" alt="">
                <img class="vip-img" :src="imgs.vip3" alt="">
              </div>
              <div>更实用、随心用</div>
              <div class="mb-3"><span class="v-symbol">￥</span><span class="v-price">698</span><span>/年</span></div>
            </div>

            <div class="d-flex flex-wrap v-tag">
              <div>
                <svg class="svg-icon" style="--color: #999999;">
                  <use xlink:href="#check"></use>
                </svg>
                <img :src="imgs.dui" class="svg-icon" alt="">
                自定义剧本目录
              </div>
              <div>
                <svg class="svg-icon" style="--color: #999999;">
                  <use xlink:href="#check"></use>
                </svg>
                <img :src="imgs.dui" class="svg-icon" alt="">
                预约组局
              </div>
              <div>
                <svg class="svg-icon" style="--color: #999999;">
                  <use xlink:href="#check"></use>
                </svg>
                <img :src="imgs.dui" class="svg-icon" alt="">
                开本资源
              </div>
              <div>
                <svg class="svg-icon" style="--color: #999999;">
                  <use xlink:href="#check"></use>
                </svg>
                <img :src="imgs.dui" class="svg-icon" alt="">
                DM展示
              </div>
              <div>
                <svg class="svg-icon" style="--color: #999999;">
                  <use xlink:href="#check"></use>
                </svg>
                <img :src="imgs.dui" class="svg-icon" alt="">
                微信商户(0.2%)
              </div>
              <div>
                <svg class="svg-icon" style="--color: #999999;">
                  <use xlink:href="#check"></use>
                </svg>
                <img :src="imgs.dui" class="svg-icon" alt="">
                店家记账收银
              </div>
              <div>
                <svg class="svg-icon" style="--color: #999999;">
                  <use xlink:href="#check"></use>
                </svg>
                <img :src="imgs.dui" class="svg-icon" alt="">
                电脑收银端
              </div>
              <div>
                <svg class="svg-icon" style="--color: #999999;">
                  <use xlink:href="#check"></use>
                </svg>
                <img :src="imgs.dui" class="svg-icon" alt="">
                会员储值系统
              </div>
              <div>
                <svg class="svg-icon" style="--color: #999999;">
                  <use xlink:href="#check"></use>
                </svg>
                <img :src="imgs.dui" class="svg-icon" alt="">
                卡券系统
              </div>
              <div>
                <svg class="svg-icon" style="--color: #999999;">
                  <use xlink:href="#check"></use>
                </svg>
                <img :src="imgs.dui" class="svg-icon" alt="">
                票根折扣
              </div>
              <div>
                <svg class="svg-icon" style="--color: #999999;">
                  <use xlink:href="#check"></use>
                </svg>
                <img :src="imgs.dui" class="svg-icon" alt="">
                店员工作记录/提成
              </div>
              <div>
                <svg class="svg-icon" style="--color: #999999;">
                  <use xlink:href="#check"></use>
                </svg>
                <img :src="imgs.dui" class="svg-icon" alt="">
                分店管理系统
              </div>
              <div>
                <svg class="svg-icon" style="--color: #999999;">
                  <use xlink:href="#check"></use>
                </svg>
                <img :src="imgs.dui" class="svg-icon" alt="">
                抽奖等营销系统
              </div>
              <div>
                <svg class="svg-icon" style="--color: #999999;">
                  <use xlink:href="#check"></use>
                </svg>
                <img :src="imgs.dui" class="svg-icon" alt="">
                扫码核销
              </div>
              <div>
                <svg class="svg-icon" style="--color: #999999;">
                  <use xlink:href="#check"></use>
                </svg>
                <img :src="imgs.dui" class="svg-icon" alt="">
                店铺黑名单
              </div>
              <div>
                <svg class="svg-icon" style="--color: #999999;">
                  <use xlink:href="#check"></use>
                </svg>
                <img :src="imgs.dui" class="svg-icon" alt="">
                集卡赢优惠
              </div>
              <div>
                <svg class="svg-icon" style="--color: #999999;">
                  <use xlink:href="#check"></use>
                </svg>
                <img :src="imgs.dui" class="svg-icon" alt="">
                员工提成绩效算法 Plus
              </div>
              <div>
                <svg class="svg-icon" style="--color: #999999;">
                  <use xlink:href="#check"></use>
                </svg>
                <img :src="imgs.dui" class="svg-icon" alt="">
                需求排期优先
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div class="about">
          <img :src="imgs.about" alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计" />
          <img class="horn" :src="imgs.horn" alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计" />
        </div>
        <div class="container about-text">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;南京铲屎帮网络科技有限公司成立于2019年7月，主营网站设计、小程序定制开发、网站建设、公众号运营等，开发人员均5年以上相关工作经验，代表案例微信小程序以及公众号品牌《谋杀之谜秘书》，目前已服务全球店家8000+，覆盖200余国内城市和18+海外城市，收录剧本7800+，解决店家经营管理难题。
        </div>
        <div class="container address">
          <div class="d-flex align-items-end">
            <div>公司总部</div>
            <div>电话 / 18021534363</div>
            <div>
              地址 / 南京市栖霞区迈皋桥创业园科技研发基地（寅春路18号F1263）
            </div>
          </div>
          <div class="d-flex align-items-end">
            <div>南京旗舰店</div>
            <div>电话 / 17366018359</div>
            <div>地址 / 南京市秦淮区石鼓路33号东方名苑A座27楼2701</div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="new-wechat">
          <div>
            <img :src="imgs.wangzai" alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计" />
            <div class="text-center">小秘书-旺仔</div>
          </div>
          <div>
            <img :src="imgs.dagou" alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计" />
            <div class="text-center">小秘书-大狗</div>
          </div>
          <div>
            <img :src="imgs.publicImg" alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计" />
            <div class="text-center">秘书公众号</div>
          </div>
        </div>
      </div>

      <div class="pc">
        <div class="join-method container">
          <div class="wechat">
            <div class="wechat-box row">
              <div class="col-12 col-sm-12 col-md-4">
                <div>
                  <img :src="imgs.dagou" alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计" />
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-4">
                <div>
                  <img :src="imgs.wangzai" alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计" />
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-4">
                <div>
                  <img :src="imgs.publicImg" alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container contact">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-6 mb-3">
              <div class="row align-items-center mb-3">
                <div class="contact-com-img">
                  <img :src="imgs.iconCom" alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计" />
                </div>
                <div>
                  <div class="contact-com-title">公司总部</div>
                  <div class="contact-com-eng">Company headquarters</div>
                </div>
              </div>
              <div class="row align-items-center mb-2">
                <div class="icon-box">
                  <img :src="imgs.iconLocal" alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计" />
                </div>
                <div class="icon-text">
                  地址 / 南京市栖霞区迈皋桥创业园科技研发基地（寅春路18号F1263）
                </div>
              </div>
              <div class="row align-items-center">
                <div class="icon-box">
                  <img :src="imgs.iconPhone" alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计" />
                </div>
                <div class="icon-text">电话 / 18021534363</div>
              </div>
            </div>
            <div class="line"></div>
            <div class="col-12 col-sm-12 col-md-6">
              <div class="row align-items-center mb-3">
                <div class="contact-com-img">
                  <img :src="imgs.iconCom" alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计" />
                </div>
                <div>
                  <div class="contact-com-title">南京旗舰店</div>
                  <div class="contact-com-eng">Nanjing flagship store</div>
                </div>
              </div>
              <div class="row align-items-center mb-2">
                <div class="icon-box">
                  <img :src="imgs.iconLocal" alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计" />
                </div>
                <div class="icon-text">
                  地址 / 南京市秦淮区石鼓路33号东方名苑A座27楼2701
                </div>
              </div>
              <div class="row align-items-center">
                <div class="icon-box">
                  <img :src="imgs.iconPhone" alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计" />
                </div>
                <div class="icon-text">电话 / 17366018359</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      imgs: {
        banner: require("@/assets/images/home/banner.png"),
        phone: require("@/assets/images/home/phone.png"),
        header1: require("@/assets/images/home/header-1.png"),
        header2: require("@/assets/images/home/header-2.png"),
        header3: require("@/assets/images/home/header-3.png"),
        header4: require("@/assets/images/home/header-4.png"),
        header5: require("@/assets/images/home/header-5.png"),
        funIntroL: require("@/assets/images/home/funIntroL.png"),
        funIntroR: require("@/assets/images/home/funIntroR.png"),
        spL: require("@/assets/images/home/spL.png"),
        spR: require("@/assets/images/home/spR.png"),
        sectionImg: require("@/assets/images/home/sectionImg.png"),
        dagou: require("@/assets/images/home/dagou.png"),
        publicImg: require("@/assets/images/home/publicImg.png"),
        wangzai: require("@/assets/images/home/wangzai.png"),
        iconCom: require("@/assets/images/home/icon-com.png"),
        iconLocal: require("@/assets/images/home/icon-local.png"),
        iconNanjing: require("@/assets/images/home/icon-nanjing.png"),
        iconPhone: require("@/assets/images/home/icon-phone.png"),
        about: require("@/assets/images/home/about.png"),
        horn: require("@/assets/images/home/horn.png"),
        dui: require("@/assets/images/dui.png"),
        vip1: require("@/assets/images/home/vip1.png"),
        vip2: require("@/assets/images/home/vip2.png"),
        vip3: require("@/assets/images/home/vip3.png"),
      },
      maImgs: [
        { maImg: require("@/assets/images/home/maImg1.png") },
        { maImg: require("@/assets/images/home/maImg2.png") },
        { maImg: require("@/assets/images/home/maImg3.png") },
        { maImg: require("@/assets/images/home/maImg4.png") },
        { maImg: require("@/assets/images/home/maImg5.png") },
        { maImg: require("@/assets/images/home/maImg6.png") },
      ],
    };
  },
  mounted() {
    // document.oncontextmenu = function () {
    //   return false;
    // };
    // document.onmouseup = function (e) {
    //   if (e.button === 2) {
    //     console.log("右键");
    //   } else if (e.button === 1) {
    //     console.log("中间键");
    //   } else {
    //     console.log("左键");
    //   }
    // };
  },
  methods: {
    aa(index) {
      let arr = document.querySelector("#animateBox").childNodes;
      arr.forEach((item) => {
        item.style.left = 0;
      });
      switch (index) {
        case 1:
          arr[0].style.left = "-25%";
          arr[1].style.left = "13%";
          arr[2].style.left = "26%";
          arr[3].style.left = "37%";
          arr[4].style.left = "50%";
          arr[5].style.left = "63%";
          break;
        case 2:
          arr[0].style.left = "-25%";
          arr[1].style.left = "-12%";
          arr[2].style.left = "26%";
          arr[3].style.left = "37%";
          arr[4].style.left = "50%";
          arr[5].style.left = "63%";
          break;
        case 3:
          arr[0].style.left = "-25%";
          arr[1].style.left = "-13%";
          arr[2].style.left = "0%";
          arr[3].style.left = "37%";
          arr[4].style.left = "50%";
          arr[5].style.left = "63%";
          break;
        case 4:
          arr[0].style.left = "-25%";
          arr[1].style.left = "-13%";
          arr[2].style.left = "0%";
          arr[3].style.left = "13%";
          arr[4].style.left = "50%";
          arr[5].style.left = "63%";
          break;
        case 5:
          arr[0].style.left = "-25%";
          arr[1].style.left = "-13%";
          arr[2].style.left = "0%";
          arr[3].style.left = "13%";
          arr[4].style.left = "26%";
          arr[5].style.left = "63%";
          break;
        case 6:
          arr[0].style.left = "-25%";
          arr[1].style.left = "-13%";
          arr[2].style.left = "0%";
          arr[3].style.left = "13%";
          arr[4].style.left = "26%";
          arr[5].style.left = "50%";
          break;
        default:
          arr[0].style.left = "0";
          arr[1].style.left = "13%";
          arr[2].style.left = "26%";
          arr[3].style.left = "37%";
          arr[4].style.left = "50%";
          arr[5].style.left = "63%";
      }
    },
    bb() {
      let arr = document.querySelector("#animateBox").childNodes;
      arr[0].style.left = "0";
      arr[1].style.left = "13%";
      arr[2].style.left = "26%";
      arr[3].style.left = "37%";
      arr[4].style.left = "50%";
      arr[5].style.left = "63%";
    },
  },
};
</script>
<style scoped>
.w-100 {
  width: 100%;
}

.centerY {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.centerZ {
  display: flex;
  align-items: center;
  justify-content: center;
}

.svg-icon {
  width: 16px;
  height: 16px;
  vertical-align: -.15em;
  fill: currentColor;
  overflow: hidden;
  color: red;
}

.w-60 {
  width: 60%;
}

.pc {
  display: none;
}

.banner {
  width: 100%;
  position: relative;
  padding-top: 8rem;
  margin-bottom: 5rem;
}

.banner-img {
  width: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
}

img {
  width: 100%;
}

.version-title {
  font-weight: 800;
  font-style: italic;
  font-size: 40px;
  color: #2b2f52;
  text-align: center;
}

.version-subtitle {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 20px;
  color: rgba(43, 47, 82, 0.5);
  text-align: center;
}

.version-item {
  width: 100%;
  background-color: #f5f5f7;
  display: flex;
  align-items: center;
  border-radius: 32px;
  padding: 30px 40px 30px 50px;
  box-sizing: border-box;
  color: #999;
  margin-bottom: 10px;
}

.v-logo {
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
  border-radius: 8px;
}

.vip-img {
  width: 140px;
  margin-bottom: 10px;
}

.v-tag {
  row-gap: 16px;
  column-gap: 20px;
}

.v-symbol {
  font-size: 26px;
  color: #000;
  font-weight: bold;
}

.v-price {
  font-size: 40px;
  color: #000;
  font-weight: bold;
}

.main #animateBox {
  transform: scale(0.8);
}

.hr .arrow {
  width: 1rem;
  text-align: right;
}

.hr .title {
  transform: translateX(-3rem) scale(1.2);
  margin-bottom: 3rem;
  margin-top: 3rem;
  width: 80%;
}

.hr .subtitle {
  color: #fff;
  font-weight: 1.2rem;
  font-weight: 600;
  font-style: italic;
}

.hr .subtitle span {
  color: #0031be;
}

.hr .shopnum {
  width: 80%;
  color: #fff;
  font-weight: 600;
  font-size: 0.95rem;
  text-align: center;
  letter-spacing: 3px;
  line-height: 1.2rem;
  padding: 2rem;
  background-image: url("../../assets/images/home/header-3.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.hr .sh {
  margin-bottom: 3rem;
  color: #fff;
  font-weight: 600;
  font-size: 0.95rem;
  text-align: center;
  letter-spacing: 3px;
  line-height: 1.2rem;
  padding: 1.2rem 1.5rem;
  background-image: url("../../assets/images/home/header-4.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.hr .ele {
  width: 40%;
  text-align: right;
  margin-top: 3rem;
}

.serP {
  width: 100%;
  margin-bottom: 3rem;
}

.serP .card-title {
  width: 40%;
  font-size: 1.2rem;
  color: white;
  background-image: url(../../assets/images/home/t-bg.png);
  background-size: contain;
  background-repeat: no-repeat;
  padding: 0.5rem 2rem;
  font-weight: 600;
  text-align: center;
}

.serP .card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #ffffff;
  font-size: 0.95rem;
  height: 300px;
  font-weight: 600;
  color: #2b2f52;
  box-shadow: 0px 40px 57px 0px #d1d6de;
  padding: 2rem;
  line-height: 3rem;
}

.serP .price .card-body {
  padding: 2.95rem;
}

.serP .card-body strong {
  color: #9a2017;
  font-size: 1rem;
}

.serP .card-body .smallT {
  font-size: 1rem;
  color: #ccc;
  font-weight: 300;
  font-style: italic;
}

.serP .card-body .bigT {
  font-size: 1rem;
  font-weight: 600;
  margin-right: 1rem;
}

.serP .card-body .smallP {
  color: #9a2017;
}

.serP .card-body .bigP {
  color: #9a2017;
  font-size: 1.5rem;
}

.main .about {
  width: 80%;
  background: linear-gradient(0deg, #4c7aff 0%, #ffffff 99%);
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  border-bottom-right-radius: 200px;
  position: relative;
  padding: 0 0 1rem 0;
  margin-top: 7rem;
}

.main .about img {
  width: 35%;
}

.main .about .horn {
  position: absolute;
  right: 0;
  top: 1rem;
  width: 15%;
}

.main .about-text {
  color: #2b2f52;
  line-height: 3rem;
  font-weight: 700;
  padding: 4rem;
  font-size: 0.95rem;
}

.main .address {
  width: 90%;
  margin: 0 auto;
  line-height: 4rem;
  padding: 0 5rem;
}

.main .address>div div:nth-child(1) {
  font-weight: 600;
  font-size: 1.3rem;
  color: #2b2f52;
  font-style: italic;
  margin-right: 3rem;
}

.main .address>div div:nth-child(2) {
  color: #2b2f52;
  margin-right: 3rem;
}

.main .address>div div:nth-child(3) {
  color: #2b2f52;
}

.new-wechat {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin-top: 5rem;
  text-align: center;
  color: #2b2f52;
  font-size: 0.95rem;
}

.new-wechat>div {
  width: 100px;
}

.join-method {
  margin-bottom: 3rem;
}

.join-method .wechat {
  width: 100%;
  margin-top: 6rem;
}

.join-method .wechat .wechat-title {
  margin: 0 auto;
  width: 60%;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.join-method .wechat .wechat-box {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.join-method .wechat .wechat-box>div>div {
  width: 70%;
  padding: 1.5rem;
  box-shadow: 0px 7px 27px 0px rgba(88, 110, 143, 0.25);
  border-radius: 1rem;
  position: relative;
  margin: 0 auto;
}

.join-method .wechat .wechat-box>div>div::before {
  position: absolute;
  display: block;
  content: "秘书公众号";
  width: 3.5rem;
  box-shadow: 0px 7px 27px 0px rgba(88, 110, 143, 0.25);
  color: #2b2f52;
  padding: 1rem;
  line-height: 24px;
  font-size: 1.3rem;
  margin-right: 0.8rem;
  font-weight: 600;
  left: -3.5rem;
  top: 50%;
  transform: translateY(-50%);
}

.join-method .wechat .wechat-box>div:nth-child(1)>div:before {
  content: "小秘书大狗";
}

.join-method .wechat .wechat-box>div:nth-child(2)>div:before {
  content: "小秘书旺仔";
}

.contact {
  width: 100%;
  color: #2b2f52;
  font-style: italic;
  font-weight: 600;
}

.contact .line {
  width: 90%;
  margin: 1rem auto 1rem;
  height: 1px;
  background-color: #eee;
  display: none;
}

.contact .contact-com-img {
  width: 15%;
  padding: 1rem;
}

.contact .contact-com-title {
  font-size: 2rem;
}

.contact .contact-com-eng {
  background-image: -webkit-linear-gradient(right, #7384ff, #70e8fe);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.contact .icon-box {
  width: 1rem;
  height: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.contact .icon-text {
  font-size: 1rem;
}

.fun-show {
  display: flex;
  position: relative;
  height: 800px;
  margin-top: 3rem;
}

.fun-show>div {
  position: absolute;
  width: 38%;
  transition: all 0.5s ease-in;
}

.fun-show>div:first-child {
  z-index: 6;
}

.fun-show>div:nth-child(2) {
  z-index: 5;
  left: 13%;
}

.fun-show>div:nth-child(3) {
  z-index: 4;
  left: 26%;
}

.fun-show>div:nth-child(4) {
  z-index: 3;
  left: 37%;
}

.fun-show>div:nth-child(5) {
  z-index: 2;
  left: 50%;
}

.fun-show>div:nth-child(6) {
  z-index: 1;
  left: 63%;
}

@media screen and (max-width: 800px) {
  .version-item {
    flex-direction: column;
  }
  .banner {
    margin-bottom: 2rem;
  }

  .hr .arrow-box,
  .hr .ele-box {
    display: none !important;
  }

  .hr .title {
    transform: initial;
    margin: 0;
  }

  .hr .shopnum {
    margin: 0;
    width: 100%;
    font-size: 0.6rem;
    padding-top: 1rem;
    background-size: contain;
  }

  .hr .sh {
    margin: 0;
    width: 70%;
    font-size: 0.6rem;
    padding: 1.5rem 2rem;
    padding-top: 1rem;
    background-size: contain;
  }

  .mini-title {
    padding: 0 2rem;
  }

  .mini-title .mini-subtitle {
    color: #2b2f52;
  }

  .main .about {
    width: 95%;
    margin-top: 1rem;
    height: 4rem;
  }

  .main .about-text {
    padding: 2rem;
  }

  .main .address {
    width: 100%;
    padding: 2rem;
  }

  .main .address>div {
    flex-direction: column;
    align-items: inherit !important;
  }

  #animateBox {
    margin-top: 1rem;
    margin-bottom: 1rem;
    flex-direction: column;
    padding: 0 2rem;
    height: auto;
    transform: scale(0.9) !important;
  }

  .fun-show>div {
    position: unset;
    width: 100%;
  }

  .serP {
    margin: 0;
  }

  .serP .card-title {
    width: auto;
  }

  .serP .card-body {
    font-size: 0.5rem;
    line-height: 2.5rem;
    margin-bottom: 1rem;
  }

  .serP .card-body strong {
    font-size: 0.8rem;
  }

  .serP .card-body {
    padding: 1rem;
  }

  .serP .price .card-body {
    padding: 2rem;
  }

  .serP .card-body .bigT {
    font-size: 1rem;
  }

  .serP .card-body .smallT {
    font-size: 0.5rem;
  }

  .serP .card-body .bigP {
    font-size: 1.5rem;
  }

  .serP .card-body .smallP {
    font-size: 1.3rem;
  }

  .join-method .wechat {
    margin-top: 1rem;
  }

  .join-method {
    margin-bottom: 1rem;
  }

  .join-method .wechat .wechat-box {
    margin: 0;
  }

  .join-method .wechat .wechat-box>div {
    padding-left: 3rem;
    margin-bottom: 1rem;
  }

  .new-wechat {
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
  }

  .new-wechat>div {
    width: 70%;
    margin-bottom: 1rem;
  }

  .contact .contact-com-title {
    font-size: 1.5rem;
  }

  .contact .icon-text {
    font-size: 0.8rem;
  }

  .contact .line {
    display: block;
  }
}
</style>
