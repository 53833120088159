<template>
  <div class="main">
    <div class="banner">
      <div class="banner-img">
        <img
          :src="imgs.banner"
          alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计"
        />
      </div>
      <div class="container">
        <img
          :src="imgs.bannerInner"
          alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计"
        />
      </div>
    </div>
    <div class="container">
      <div class="join-method mb-3">
        <div class="our-service">
          <img
            :src="imgs.ourService"
            alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计"
          />
        </div>
        <div class="intro">
          <div class="intro-point">
            <div class="who">
              <img
                :src="imgs.whoImg"
                alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计"
              />
            </div>
            我们是剧本杀店家拼车群管机器人：
          </div>

          群内自动回复
          <span
            >剧本拼车、拼车信息、充值活动、剧本查询、新人欢迎 退群通知、点歌、星座等</span
          >
          重复的工作。一切交给小蜜打理，一起打造智能社群，24h服务不停歇！!
        </div>
        <div class="ICH">
          <img
            :src="imgs.helpTitile"
            alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计"
          />
        </div>
        <div class="shell">
          <div class="join-step-box">
            <div class="join-step row">
              <div class="shopper row col-12 col-md-6 col-sm-12 mb-3">
                <div class="title col-md-1">秘书相关</div>
                <div class="col-11">
                  <div class="d-flex step">
                    <div class="d-flex flex-column" @click="alertPop(0)">
                      <div class="icon-img">
                        <img
                          :src="imgs.iconJvben"
                          alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计"
                        />
                      </div>
                      <div>剧本搜索</div>
                    </div>
                    <div class="d-flex flex-column" @click="alertPop(1)">
                      <div class="icon-img">
                        <img
                          :src="imgs.iconShop"
                          alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计"
                        />
                      </div>
                      <div>店内活动</div>
                    </div>
                  </div>
                  <div class="d-flex step">
                    <div class="d-flex flex-column" @click="alertPop(2)">
                      <div class="icon-img">
                        <img
                          :src="imgs.iconNum"
                          alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计"
                        />
                      </div>
                      <div>场次信息</div>
                    </div>
                    <div class="d-flex flex-column" @click="alertPop(3)">
                      <div class="icon-img">
                        <img
                          :src="imgs.iconDm"
                          alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计"
                        />
                      </div>
                      <div>DM介绍</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="issue row col-12 col-md-6 col-sm-12 mb-3">
                <div class="title col-md-1">气氛相关</div>
                <div class="col-11">
                  <div class="d-flex step">
                    <div class="d-flex flex-column" @click="alertPop(4)">
                      <div class="icon-img">
                        <img
                          :src="imgs.iconNew"
                          alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计"
                        />
                      </div>
                      <div>新人欢迎</div>
                    </div>
                    <div class="d-flex flex-column" @click="alertPop(5)">
                      <div class="icon-img">
                        <img
                          :src="imgs.iconSmile"
                          alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计"
                        />
                      </div>
                      <div>讲笑话</div>
                    </div>
                    <div class="d-flex flex-column" @click="alertPop(6)">
                      <div class="icon-img">
                        <img
                          :src="imgs.iconSong"
                          alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计"
                        />
                      </div>
                      <div>点歌</div>
                    </div>
                  </div>
                  <div class="d-flex step">
                    <div class="d-flex flex-column" @click="alertPop(7)">
                      <div class="icon-img">
                        <img
                          :src="imgs.iconStar"
                          alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计"
                        />
                      </div>
                      <div>星座</div>
                    </div>
                    <div class="d-flex flex-column" @click="alertPop(8)">
                      <div class="icon-img">
                        <img
                          :src="imgs.iconUser"
                          alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计"
                        />
                      </div>
                      <div>退群通知</div>
                    </div>
                    <div class="d-flex flex-column">
                      <div
                        class="icon-img icon-point"
                        style="
                          display: flex;
                          justify-content: center;
                          align-items: center;
                          width: 4rem;
                          height: 4rem;
                        "
                      >
                        <img
                          :src="imgs.iconPoint"
                          alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计"
                        />
                      </div>
                      <div>还有更多</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div style="width: 50%; margin: 0 auto; margin-top: 6rem">
            <img
              :src="imgs.moreImg"
              alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计"
            />
          </div>
        </div>
        <div class="wechat">
          <div class="wechat-title">
            <div class="w-100 d-flex justify-content-center">
              <div>扫码联系客服或关注公众号</div>
              <div>了解小蜜价格等更多相关资讯哟~</div>
            </div>
          </div>
          <div class="wechat-box row">
            <div class="col-12 col-sm-12 col-md-4">
              <img
                :src="imgs.dagou"
                alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计"
              />
            </div>
            <div class="col-12 col-sm-12 col-md-4">
              <img
                :src="imgs.publicImg"
                alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计"
              />
            </div>
            <div class="col-12 col-sm-12 col-md-4">
              <img
                :src="imgs.wangzai"
                alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pop" v-if="showPop" @click="closePop">
      <div class="xiaomi">
        <div class="xiaomi-title">
          <div>- {{ popModel.title }} -</div>
          <div>{{ popModel.english }}</div>
        </div>
        <div class="xiaomi-phone">
          <img
            :src="popModel.imgUrl"
            alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      showPop: false,
      imgs: {
        banner: require("@/assets/images/robot/banner.png"),
        bannerInner: require("@/assets/images/robot/banner-inner.png"),
        iconDm: require("@/assets/images/robot/icon-dm.png"),
        iconJvben: require("@/assets/images/robot/icon-jvben.png"),
        iconNew: require("@/assets/images/robot/icon-new.png"),
        iconNum: require("@/assets/images/robot/icon-num.png"),
        iconShop: require("@/assets/images/robot/icon-shop.png"),
        iconSmile: require("@/assets/images/robot/icon-smile.png"),
        iconSong: require("@/assets/images/robot/icon-song.png"),
        iconStar: require("@/assets/images/robot/icon-star.png"),
        iconUser: require("@/assets/images/robot/icon-user.png"),
        whoImg: require("@/assets/images/robot/who.png"),
        moreImg: require("@/assets/images/robot/more.png"),
        ourService: require("@/assets/images/robot/ourService.png"),
        helpTitile: require("@/assets/images/robot/helpTitile.png"),
        iconPoint: require("@/assets/images/robot/point.png"),
        dagou: require("@/assets/images/robot/dagou.png"),
        publicImg: require("@/assets/images/robot/publicImg.png"),
        wangzai: require("@/assets/images/robot/wangzai.png"),
      },
      showFunImgs: [
        { img: require("@/assets/images/robot/showFun/jvben.png") },
        { img: require("@/assets/images/robot/showFun/coupons.png") },
        { img: require("@/assets/images/robot/showFun/num.png") },
        { img: require("@/assets/images/robot/showFun/DM.png") },
        { img: require("@/assets/images/robot/showFun/welcome.png") },
        { img: require("@/assets/images/robot/showFun/joke.png") },
        { img: require("@/assets/images/robot/showFun/song.png") },
        { img: require("@/assets/images/robot/showFun/constellation.png") },
        { img: require("@/assets/images/robot/showFun/exit.png") },
      ],
      popModel: {
        title: "",
        english: "",
        imgUrl: "",
      },
    };
  },
  methods: {
    alertPop(v) {
      this.showPop = true;
      switch (v) {
        case 0:
          this.popModel.title = "剧本搜索";
          this.popModel.english = "Script search";
          this.popModel.imgUrl = this.showFunImgs[v].img;
          break;
        case 1:
          this.popModel.title = "店内活动";
          this.popModel.english = "Activity";
          this.popModel.imgUrl = this.showFunImgs[v].img;
          break;
        case 2:
          this.popModel.title = "场次信息";
          this.popModel.english = "Number of sessions";
          this.popModel.imgUrl = this.showFunImgs[v].img;
          break;
        case 3:
          this.popModel.title = "DM介绍";
          this.popModel.english = "DM";
          this.popModel.imgUrl = this.showFunImgs[v].img;
          break;
        case 4:
          this.popModel.title = "新人欢迎";
          this.popModel.english = "Welcome";
          this.popModel.imgUrl = this.showFunImgs[v].img;
          break;
        case 5:
          this.popModel.title = "讲笑话";
          this.popModel.english = "Play Joke";
          this.popModel.imgUrl = this.showFunImgs[v].img;
          break;
        case 6:
          this.popModel.title = "点歌";
          this.popModel.english = "Choose Song";
          this.popModel.imgUrl = this.showFunImgs[v].img;
          break;
        case 7:
          this.popModel.title = "星座";
          this.popModel.english = "constellation";
          this.popModel.imgUrl = this.showFunImgs[v].img;
          break;
        case 8:
          this.popModel.title = "退群通知";
          this.popModel.english = "Exit Group";
          this.popModel.imgUrl = this.showFunImgs[v].img;
          break;
        default:
          break;
      }
    },
    closePop() {
      this.showPop = false;
    },
  },
};
</script>
<style scoped>
.banner {
  width: 100%;
  position: relative;
  padding-top: 5rem;
  margin-bottom: 5rem;
}
.banner-img {
  width: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
}
img {
  width: 100%;
}
.banner-inner {
  width: 60%;
}

.join-method {
  width: 100%;
}
.our-service {
  width: 30%;
  margin: 0 auto;
  margin-bottom: 5rem;
}
.join-method .intro {
  width: 100%;
  background-color: #fff;
  border-radius: 1rem;
  font-size: 1rem;
  padding: 1.5rem 2rem;
  line-height: 3rem;
  font-weight: 560;
  margin-bottom: 3rem;
}
.join-method .intro span {
  color: #3b63f1;
}
.join-method .intro .intro-point {
  position: relative;
  display: inline-block;
  background-color: #3b63f1;
  color: #fff;
  padding: 0.2rem 0.5rem;
  font-weight: 600;
  border-radius: 1rem;
  font-size: 1.2rem;
  margin-right: 1rem;
}
.join-method .ICH {
  width: 20%;
}
.join-method .intro .intro-point .who {
  width: 40%;
  position: absolute;
  top: -120%;
}

.join-method .shell {
  width: 100%;
  display: flex;
}
.join-method .join-step-box {
  display: flex;
  width: 100%;
  justify-content: center;
  align-content: center;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 1rem 1rem 1.5rem 1rem;
}

.join-method .join-step {
  width: 100%;
  justify-content: space-between;
}
.join-method .join-step > div {
  width: 48%;
  padding: 2rem 1rem;
  background-color: #fff;
  border-radius: 1rem;
  align-items: start;
}
.join-method .join-step > div .title {
  width: 3rem;
  font-size: 1.6rem;
  font-weight: 600;
  background-color: #3b63f1;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-radius: 0.5rem;
}
.join-method .join-step .issue {
}
.join-method .join-step .issue .title {
}
.join-method .join-step > div .step {
  width: 100%;
  justify-content: space-evenly;
}
.join-method .join-step > div .step > div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  padding: 1.3rem 2rem;
  border-radius: 1rem;
  transform: scale(0.9);
}
.join-method .join-step .shopper > div .step > div:hover {
  background-color: rgba(81, 155, 255, 0.1);
}
.join-method .join-step .issue > div .step > div:hover {
  background-color: rgba(255, 157, 70, 0.1);
}

.join-method .wechat {
  width: 100%;
}
.join-method .wechat .wechat-title {
  margin: 0 auto;
  width: 100%;
  color: #3a63db;
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: 3rem;
}
.join-method .wechat .wechat-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.join-method .wechat .wechat-box > div {
  padding: 5rem;
}
.pop {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
}
.pop .xiaomi {
  position: relative;
  background: #fff;
  width: 35rem;
  height: 35rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 3rem;
  background-image: url(../../assets/images/robot/xiaomi-bg.png);
  background-size: cover;
}
.pop .xiaomi .xiaomi-title {
  position: absolute;
  left: 10%;
  top: 20%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.pop .xiaomi .xiaomi-title div:first-child {
  color: #2f394c;
  font-size: 1.5rem;
  font-weight: 600;
}
.pop .xiaomi .xiaomi-title div:nth-child(2) {
  color: #ccc;
  font-size: 1rem;
  font-weight: 600;
}
.pop .xiaomi .xiaomi-phone {
  position: absolute;
  width: 50%;
  right: 0;
  top: -2.8rem;
  right: 2rem;
  border-radius: 1.5rem;
  overflow: hidden;
}

@media screen and (max-width: 500px) {
  .banner {
    margin-bottom: 1rem;
  }
  .our-service {
    width: 100%;
    margin-bottom: 1rem;
    transform: initial;
  }
  .join-method .intro {
    padding: 1rem;
    font-size: 0.8rem;
    line-height: 2rem;
    margin-bottom: 2rem;
    box-shadow: 0 2px 2px rgb(10 16 20 / 24%), 0 0 2px rgb(10 16 20 / 12%);
  }
  .join-method .intro .intro-point {
    font-size: 1rem;
  }
  .join-method .intro .intro-point .who {
    width: 30%;
    top: -2rem;
  }
  .join-method .ICH {
    width: 60%;
    margin: 0 auto;
  }
  .join-method .join-step-box {
    padding: 0;
  }
  .join-method .join-step > div {
    width: 100%;
    box-shadow: 0 2px 2px rgb(10 16 20 / 24%), 0 0 2px rgb(10 16 20 / 12%);
    flex-wrap: nowrap;
    margin: 0 auto;
  }
  .join-method .join-step > div .step > div {
    width: auto;
    padding: 0.5rem !important;
    transform: scale(1);
  }
  .join-method .join-step > div .step > div .icon-img {
    width: 4rem;
    height: 4rem;
    overflow: hidden;
    transform: scale(0.8);
  }
  .join-method .join-step > div .step > div .icon-img img {
    width: 100%;
  }
  .join-method .join-step > div .title {
    font-size: 1.2rem;
  }
  .join-method .wechat .wechat-title {
    margin-top: 1rem;
    margin-bottom: 2rem;
    font-size: 1rem;
    line-height: 2rem;
  }
  .join-method .wechat .wechat-title .d-flex {
    flex-direction: column;
    align-items: center;
  }
  .join-method .wechat .wechat-box {
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
  }
  .join-method .wechat .wechat-box > div {
    width: 80%;
    padding: 1rem;
    margin-bottom: 1rem;
  }
}
</style>
