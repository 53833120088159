import Cookies from 'js-cookie'

const navActive = 'navActive'

export function getNavNum() {
    return Cookies.get(navActive)
}
export function setNavNum(active) {
    return Cookies.set(navActive, active)
}
export function removeNavNum() {
    return Cookies.remove(navActive)
}