<template>
  <div class="main">
    <div class="banner mb-3">
      <img
        :src="imgs.banner"
        alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计"
      />
    </div>
    <div class="container">
      <div class="content">
        <div class="con-navL">
          <div
            class="nav-item"
            v-for="(item, index) in menu"
            :key="index"
            :class="{ active: active == item.id }"
            @click="switchTab(item.id)"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="con-navR" v-if="active == 1">
          <div class="title">发行注册</div>
          <div class="article">
            <div class="headline">
              <div class="orderNum">1</div>
              您需要提前准备以下资料
            </div>
            <div class="text d-flex justify-content-between align-items-center">
              <div>① 发行logo/头像</div>
              <div>② 工作室/公司名称</div>
              <div>③ 联系电话</div>
            </div>
            <div class="headline">
              <div class="orderNum">2</div>
              扫描下方二维码申请注册------------或------------扫码添加客服微信/关注公众号进行注册
            </div>
            <div class="text">
              <div class="d-flex justify-content-between align-items-center">
                <div class="img-block">
                  <img
                    :src="imgs.issueCode"
                    alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计"
                  />
                  <div>扫码注册</div>
                </div>
                <div class="row">
                  <div class="img-block mr-3">
                    <img
                      :src="imgs.dagou"
                      alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计"
                    />
                    <div>小秘书 - 大狗</div>
                  </div>
                  <div class="img-block">
                    <img
                      :src="imgs.publicImg"
                      alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计"
                    />
                    <div>秘书公众号</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-12 col-md-6">
                <div class="headline">
                  <div class="orderNum">3</div>
                  填写资料
                </div>
                <div class="text">
                  <div class="phone-img">
                    <img
                      :src="imgs.issueJoinA"
                      alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计"
                    />
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6">
                <div class="headline">
                  <div class="orderNum">4</div>
                  等待审核
                </div>
                <div class="text">
                  <div class="phone-img">
                    <img
                      :src="imgs.issueJoinB"
                      alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="con-navR" v-if="active == 0">
          <div class="title">店家注册</div>
          <div class="article">
            <div class="headline">
              <div class="orderNum">1</div>
              您需要提前准备以下资料
            </div>
            <div class="text d-flex justify-content-between align-items-center">
              <div>① 店家logo/头像</div>
              <div>② 营业执照</div>
              <div>③ 法人身份证人像</div>
              <div>④ 门店照片</div>
            </div>
            <div class="headline">
              <div class="orderNum">2</div>
              扫描下方二维码申请注册------------或------------扫码添加客服微信/关注公众号进行注册
            </div>
            <div class="text">
              <div class="d-flex justify-content-between align-items-center">
                <div class="img-block">
                  <img
                    :src="imgs.shopCode"
                    alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计"
                  />
                  <div>扫码注册</div>
                </div>
                <div class="row">
                  <div class="img-block mr-3">
                    <img
                      :src="imgs.dagou"
                      alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计"
                    />
                    <div>小秘书 - 大狗</div>
                  </div>
                  <div class="img-block">
                    <img
                      :src="imgs.publicImg"
                      alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计"
                    />
                    <div>秘书公众号</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-12 col-md-6">
                <div class="headline">
                  <div class="orderNum">3</div>
                  填写资料
                </div>
                <div class="text">
                  <div class="phone-img">
                    <img
                      :src="imgs.shopJoinB"
                      alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计"
                    />
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6">
                <div class="headline">&nbsp;</div>
                <div class="text">
                  <div class="phone-img">
                    <img
                      :src="imgs.shopJoinA"
                      alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-12 col-md-6">
                <div class="headline">
                  <div class="orderNum">4</div>
                  等待审核
                </div>
                <div class="text">
                  <div class="phone-img">
                    <img
                      :src="imgs.shopJoinC"
                      alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计"
                    />
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 d-flex align-items-center">
                <div class="d-flex tips">
                  <div class="horn">
                    <img
                      :src="imgs.horn"
                      alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计"
                    />
                  </div>
                  审核将在24h内完成，提交后等待客服联系 也可以主动添加客服咨询哟
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="global-float">
          <!-- <div class="card c-article">
          <div class="card-title">
            <div class="point-t"></div>
            <div>热点文章</div>
          </div>
          <div class="card-body">
            <div class="article-c">
              <div class="d-flex align-items-center">
                <div class="wz-con">
                  <div class="wz-title">文章标题文章标题</div>
                  <div>文章文章文章文章文章文 章文章文章...</div>
                </div>
                <div class="wz-img"></div>
              </div>
              <div class="d-flex align-items-center">
                <div class="wz-con">
                  <div class="wz-title">文章标题文章标题</div>
                  <div>文章文章文章文章文章文 章文章文章...</div>
                </div>
                <div class="wz-img"></div>
              </div>
              <div class="d-flex align-items-center">
                <div class="wz-con">
                  <div class="wz-title">文章标题文章标题</div>
                  <div>文章文章文章文章文章文 章文章文章...</div>
                </div>
                <div class="wz-img"></div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="btn">查看更多</div>
          </div>
        </div> -->
          <router-link :to="{ path: '/about/13' }">
            <div class="card c-function">
              <div class="card-title">
                <div class="point-t"></div>
                <div>功能介绍</div>
              </div>
              <div class="card-body">
                <div class="func-c">
                  <div class="d-flex flex-wrap">
                    <div class="item">简介</div>
                    <div class="item">剧本和目录</div>
                    <div class="item">会员和卡券</div>
                    <div class="item">预约和组局</div>
                    <div class="item">买单和价格</div>
                    <div class="item">卡券和抽奖活动</div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="btn">
                  <router-link :to="{ path: '/about/13' }">
                    查看更多
                  </router-link>
                </div>
              </div>
            </div>
          </router-link>
          <router-link :to="{ path: '/about/14' }">
            <div class="card c-problem">
              <div class="card-title">
                <div class="point-t"></div>
                <div>常见问题</div>
              </div>
              <div class="card-body">
                <div class="problem-c">
                  <div>
                    <div class="d-flex align-items-center">
                      <div class="serial-num">1</div>
                      <div class="item">如何上架剧本</div>
                    </div>
                    <div class="d-flex align-items-center">
                      <div class="serial-num">2</div>
                      <div class="item">如何添加店员</div>
                    </div>
                    <div class="d-flex align-items-center">
                      <div class="serial-num">3</div>
                      <div class="item">如何生成剧本海报</div>
                    </div>
                    <div class="d-flex align-items-center">
                      <div class="serial-num">4</div>
                      <div class="item">剧本目录自定义</div>
                    </div>
                    <div class="d-flex align-items-center">
                      <div class="serial-num">5</div>
                      <div class="item">剧本下架</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="btn">
                  <router-link :to="{ path: '/about/14' }">
                    查看更多
                  </router-link>
                </div>
              </div>
            </div>
          </router-link>
          <router-link :to="{ name: 'update' }">
            <div class="card c-update">
              <div class="card-title">
                <div class="point-t"></div>
                <div>更新日志</div>
              </div>
              <div class="card-body">
                <div class="update-c">
                  <div>
                    <div class="version">版本号: 3.13.35</div>
                    <div class="v-time">2021.9.16-16:35</div>
                    <div class="v-detail">
                      新增：充值活动以及会员卡券活动...
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="btn">
                  <router-link :to="{ name: 'update' }"> 查看更多 </router-link>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      active: 0,
      imgs: {
        banner: require("@/assets/images/join-banner.png"),
        issueCode: require("@/assets/images/join/issue-code.png"),
        shopCode: require("@/assets/images/join/shop-code.png"),
        issueJoinA: require("@/assets/images/join/issue-join.png"),
        issueJoinB: require("@/assets/images/join/issue-join1.png"),
        shopJoinB: require("@/assets/images/join/shop-join.png"),
        shopJoinA: require("@/assets/images/join/shop-join1.png"),
        shopJoinC: require("@/assets/images/join/shop-join2.png"),
        dagou: require("@/assets/images/home/dagou.png"),
        publicImg: require("@/assets/images/home/publicImg.png"),
        horn: require("@/assets/images/horn.png"),
      },
      menu: [
        { id: 0, name: "店家注册" },
        { id: 1, name: "发行注册" },
      ],
    };
  },
  methods: {
    switchTab(v) {
      this.active = v;
    },
  },
};
</script>
<style scoped>
img {
  width: 100%;
}
.main .content {
  width: 100%;
  display: flex;
}
.main .content .con-navL {
  width: 20%;
}
.main .content .con-navL .nav-item {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  padding: 1.8rem;
  background-color: #f5f5f5;
  text-align: center;
  font-weight: 600;
}
.main .content .con-navL .nav-item.active {
  background-color: #c6deff;
  color: #323e81;
}
.main .content .con-navL .nav-item.active::after {
  display: flex;
  content: "";
  position: absolute;
  width: 0.5rem;
  height: 100%;
  background-color: #566aff;
  right: 0;
  top: 0;
}
.main .content .con-navR {
  width: 60%;
  padding: 1rem;
}
.main .content .con-navR .title {
  width: 100%;
  font-weight: 600;
  text-align: center;
  font-size: 1.2rem;
}
.main .content .con-navR .article {
  width: 100%;
}
.main .content .con-navR .article .headline {
  width: 100%;
  font-size: 1rem;
  font-weight: 600;
  padding: 1rem;
  color: rgba(0, 0, 0, 0.8);
}
.main .content .con-navR .article .headline .orderNum {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 500;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  background-color: #566aff;
  color: #fff;
}
.main .content .con-navR .article .text {
  width: 100%;
  font-size: 0.95rem;
  padding: 1rem;
  line-height: 2rem;
  padding-left: 3rem;
  color: rgba(0, 0, 0, 0.8);
}
.main .content .con-navR .article .text .img-block {
  width: 100px;
  text-align: center;
  color: rgba(0, 0, 0, 0.7);
  font-size: 1rem;
  font-weight: 600;
}
.main .content .con-navR .article .text .phone-img {
  width: 80%;
}
.tips {
  position: relative;
  width: 100%;
  background: linear-gradient(86deg, #566aff 0%, #7fbeff 99%);
  border-radius: 20px;
  color: #ffff;
  padding: 1rem;
  font-size: 0.95rem;
}
.horn {
  position: absolute;
  width: 30%;
  right: 0;
  top: 0;
  transform: translateY(-50%);
}
@media screen and (max-width: 500px) {
  .main .content {
    flex-direction: column;
  }
  .main .content .con-navL {
    width: 100%;
    display: flex;
  }
  .main .content .con-navL .nav-item {
    flex: 0 1 50%;
    padding: 1rem;
    width: auto;
    font-size: 1rem;
    font-weight: 500;
  }
  .main .content .con-navL .nav-item.active::after {
    display: none;
  }
  .main .content .con-navR {
    width: 100%;
    padding: 1rem 0.5rem;
  }
  .main .content .con-navR .article .headline {
    padding: 1rem 0;
  }
  .main .content .con-navR .article .text {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 0;
    padding-left: 0;
  }
  .main .content .con-navR .article .text > div {
    flex-direction: column;
    align-items: center;
  }
  .main .content .con-navR .article .text > div .row {
    flex-direction: column;
    align-items: center;
  }
  .main .content .con-navR .article .text .img-block {
    margin-right: 0 !important;
  }
  .main .content .con-navR .article .text .phone-img {
    margin: auto;
  }
  .main .content .con-navR .tips {
    padding: 1rem;
    border-radius: 1rem;
    font-size: 1rem;
    margin-top: 3rem;
  }
}
</style>
