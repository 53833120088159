<template>
  <div class="main">
    <div class="banner mb-3">
      <img
        :src="imgs.banner"
        alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计"
      />
    </div>
    <div class="container d-flex">
      <div class="global-float">
        <!-- <div class="card">
          <div class="card-title">
            <div class="point-t"></div>
            <div>热点文章</div>
          </div>
          <div class="card-body">
            <div class="article-c">
              <div class="d-flex align-items-center">
                <div class="wz-con">
                  <div class="wz-title">文章标题文章标题</div>
                  <div>文章文章文章文章文章文 章文章文章...</div>
                </div>
                <div class="wz-img"></div>
              </div>
              <div class="d-flex align-items-center">
                <div class="wz-con">
                  <div class="wz-title">文章标题文章标题</div>
                  <div>文章文章文章文章文章文 章文章文章...</div>
                </div>
                <div class="wz-img"></div>
              </div>
              <div class="d-flex align-items-center">
                <div class="wz-con">
                  <div class="wz-title">文章标题文章标题</div>
                  <div>文章文章文章文章文章文 章文章文章...</div>
                </div>
                <div class="wz-img"></div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="btn">查看更多</div>
          </div>
        </div> -->

        <router-link :to="{ path: '/about/13' }">
          <div class="card c-function" v-if="pageId == 14">
            <div class="card-title">
              <div class="point-t"></div>
              <div>功能介绍</div>
            </div>
            <div class="card-body">
              <div class="func-c">
                <div class="d-flex flex-wrap">
                  <div class="item">简介</div>
                  <div class="item">剧本和目录</div>
                  <div class="item">会员和卡券</div>
                  <div class="item">预约和组局</div>
                  <div class="item">买单和价格</div>
                  <div class="item">卡券和抽奖活动</div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="btn">
                <router-link :to="{ path: '/about/13' }">
                  查看更多
                </router-link>
              </div>
            </div>
          </div>
        </router-link>
        <div class="card">
          <div class="card-title">
            <div class="point-t"></div>
            <div>注册指引</div>
          </div>
          <div class="card-body">
            <div class="register-c">
              <div class="d-flex flex-wrap">
                <div class="item">
                  <router-link :to="{ name: 'settled' }">
                    店家注册
                  </router-link>
                </div>
                <div class="item">
                  <router-link :to="{ name: 'settled' }">
                    商家注册
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="card">
          <div class="card-title">
            <div class="point-t"></div>
            <div>热点文章</div>
          </div>
          <div class="card-body">
            <div class="problem-c">
              <div>
                <div class="d-flex align-items-center">
                  <div class="serial-num">1</div>
                  <div class="item">如何添加店员</div>
                </div>
                <div class="d-flex align-items-center">
                  <div class="serial-num">2</div>
                  <div class="item">如何生成剧本海报</div>
                </div>
                <div class="d-flex align-items-center">
                  <div class="serial-num">3</div>
                  <div class="item">店家发起固定组局-玩家加入</div>
                </div>
                <div class="d-flex align-items-center">
                  <div class="serial-num">4</div>
                  <div class="item">商家组局设置/包间设置</div>
                </div>
                <div class="d-flex align-items-center">
                  <div class="serial-num">5</div>
                  <div class="item">储值设置/会员设置</div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="btn">查看更多</div>
          </div>
        </div> -->
      </div>
      <div class="content-box">
        <div class="content">
          <div class="v-text">
            <span>版本号：3.13.23</span>
            <span class="ml-3 text-muted">2021年8月19日 10:48</span>
          </div>
          <div class="text-line">
            <div class="label">增加:</div>
            <div class="text">
              优惠券可设置：仅节假日可用、仅工作日可用、仅非高峰期可用、仅高峰期可用、不限制<br />
              <span class="text-muted">
                （高峰期：节假日和节假日前一天，非高峰期：不包括放假前一天的工作日，工作日并不是死板
                的周一 ~周五哦，根据国家法定节假日时间标准得出）；</span
              >
            </div>
          </div>
          <div class="text-line">
            <div class="label">增加:</div>
            <div class="text">
              优惠券可设置：仅限盒装/精盒/城限/独家/桌游......剧本可用；
            </div>
          </div>
          <div class="text-line">
            <div class="label">优化：</div>
            <div class="text">新用户进入首页可查看附近店铺列表并选择店铺</div>
          </div>
          <div class="text-line">
            <div class="label">修复：</div>
            <div class="text">预订包间然后满员订金入账的bug</div>
          </div>
          <div class="text-line">
            <div class="label">修复：</div>
            <div class="text">修复一些已知bug，优化一些UI；</div>
          </div>
        </div>
        <div class="content">
          <div class="v-text">
            <span>版本号：3.13.23</span>
            <span class="ml-3 text-muted">2021年8月19日 10:48</span>
          </div>
          <div class="text-line">
            <div class="label">增加:</div>
            <div class="text">群聊机器人小蜜内测发布</div>
          </div>
          <div class="text-line">
            <div class="label">增加:</div>
            <div class="text">预订订金可按照剧本类型设置了</div>
          </div>
          <div class="text-line">
            <div class="label">优化：</div>
            <div class="text">
              组局页选择剧本，增加人数条件，一键筛选所有人未玩过的n人本；
            </div>
          </div>
          <div class="text-line">
            <div class="label">修复：</div>
            <div class="text">发起抽奖奖品过多导致失败的bug；</div>
          </div>
          <div class="text-line">
            <div class="label">修复：</div>
            <div class="text">买单页部分情况下会员卡不可使用的bug；</div>
          </div>
        </div>
        <div class="content">
          <div class="v-text">
            <span>版本号：3.13.23</span>
            <span class="ml-3 text-muted">2021年8月19日 10:48</span>
          </div>
          <div class="text-line">
            <div class="label">增加:</div>
            <div class="text">
              自定义汇率结算：国外以及港澳台店铺可使用港币、美元、日元等作为计价单位，设置汇率后小程序内使用人民币买单自动计算
              <span class="text-muted">
                （会员设置-选择外币+自定义汇率）；
              </span>
            </div>
          </div>
          <div class="text-line">
            <div class="label">增加:</div>
            <div class="text">
              会员卡可设置最低消费了
              <span class="text-muted"> （满足最低消费才可以使用）； </span>
            </div>
          </div>
          <div class="text-line">
            <div class="label">增加:</div>
            <div class="text">
              会员卡可设置按剧本类型进行不同折扣了
              <span class="text-muted">
                （例：盒装-￥10、城限-￥15...需要发起收款时关联场
                次建议开启严格模式）；
              </span>
            </div>
          </div>
          <div class="text-line">
            <div class="label">增加:</div>
            <div class="text">
              店铺新增自定义剧本类型-精盒
              <span class="text-muted"> （精品盒装）； </span>
            </div>
          </div>
          <div class="text-line">
            <div class="label">增加:</div>
            <div class="text">
              店员可根据星期选择工作时间
              <span class="text-muted">
                （状态：休息；工作时间段：周一~周日）；</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      imgs: {
        banner: require("@/assets/images/update-banner.png"),
      },
    };
  },
  methods: {},
};
</script>
<style scoped>
img {
  width: 100%;
}
.main .content-box {
  width: 70%;
}
.main .content {
  width: 100%;
  padding: 0 2rem;
  margin-bottom: 2rem;
}
.text-muted {
  color: rgba(0, 0, 0, 0.5);
}
.main .content .v-text {
  padding: 2rem 0;
}
.main .content .text-line {
  width: 100%;
  display: flex;
  font-size: 0.95rem;
  line-height: 2rem;
}
.main .content .text-line .label {
  display: inline-block;
  width: 8%;
}

.main .content .text-line .text {
  display: inline-block;
  width: 90%;
}
@media screen and (max-width: 500px) {
  .main .content {
    padding: 0 1rem;
  }
  .main .content .v-text {
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
  }
  .main .content .v-text .text-muted {
    text-align: right;
  }
  .main .content .text-line {
    flex-direction: column;
  }
  .main .content .text-line .label {
    width: 100%;
    font-weight: 600;
  }
  .main .content .text-line .text {
    font-size: 0.9rem;
  }
}
</style>