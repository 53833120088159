<template>
  <div>
    <div class="header" v-bind:class="classObject" ref="header">
      <div class="container">
        <div class="p-3">
          <ul class="d-flex justify-content-around">
            <!-- <li
              v-for="item in navlist"
              :key="item.id"
              :class="{ active: active == item.id }"
              @click="goPage(item)"
            >
              {{ item.name }}
            </li> -->
            <router-link :to="{ name: 'home' }">
              <li :class="{ active: active == 0 }" @click="goPage(0)">首页</li>
            </router-link>
            <li class="special" :class="{ active: active == 1 }">
              谋杀之谜秘书
              <div class="fun" @click="routeChange">
                <div @click="sendId(13)">功能介绍</div>
                <div>
                  <router-link :to="{ name: 'settled' }">注册指引 </router-link>
                </div>
                <div @click="sendId(14)">常见问题</div>
                <div>
                  <router-link :to="{ name: 'update' }">更新日志 </router-link>
                </div>
                <!-- <div @click="sendId(45)">热点文章</div> -->
              </div>
            </li>
            <router-link :to="{ name: 'robot' }">
              <li :class="{ active: active == 2 }" @click="goPage(2)">
                机器人小蜜
              </li>
            </router-link>
            <router-link :to="{ name: 'joinUs' }">
              <li :class="{ active: active == 3 }" @click="goPage(3)">
                申请注册
              </li>
            </router-link>
          </ul>
        </div>
      </div>
    </div>

    <div class="mini-header">
      <div class="ac-header" :class="{ active: miniActive }">
        <div class="menu-flag" @click="toggleMenu"></div>
        <div class="logo-box">
          <div class="logo d-flex mr-1">
            <img :src="imgs.logo" mode="" />
          </div>
          <div>谋杀之谜秘书</div>
        </div>
      </div>
      <ul @click="toggleMenu">
        <router-link :to="{ name: 'home' }">
          <li :class="{ active: active == 0 }" @click="goPage(0)">首页</li>
        </router-link>
        <a><li @click="sendId(13)">功能介绍</li></a>
        <router-link :to="{ name: 'settled' }"><li>注册指引</li> </router-link>
        <a><li @click="sendId(14)">常见问题</li></a>
        <a href="javascript"
          ><router-link :to="{ name: 'update' }"
            ><li>更新日志</li>
          </router-link></a
        >
        <!-- <a><li @click="sendId(14)">热点文章</li></a> -->
        <a href="javascript"
          ><router-link :to="{ name: 'robot' }">
            <li :class="{ active: active == 2 }" @click="goPage(2)">
              机器人小蜜
            </li>
          </router-link></a
        >
        <router-link :to="{ name: 'joinUs' }">
          <li :class="{ active: active == 3 }" @click="goPage(3)">申请注册</li>
        </router-link>
      </ul>
    </div>

    <div class="main">
      <router-view />
    </div>
    <div class="footer">
      <div class="container">
        <div class="d-flex justify-content-center">
          <span>©2020-2029</span>
          <span><a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">苏ICP备19037493号-2</a></span>
          <span>增值电信业务经营许可证： 苏B2-20201746</span>
          <span>版权所有：南京铲屎帮网络科技有限公司</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderMenu",
  props: {
    isShow: Boolean,
  },
  data: function () {
    return {
      miniActive: false,
      navlist: [
        { id: 0, name: "首页", url: "/home" },
        { id: 1, name: "谋杀之谜秘书", url: "/about" },
        { id: 2, name: "机器人小蜜", url: "/robot" },
        { id: 3, name: "申请注册", url: "/joinUs" },
      ],
      imgs: {
        logo: require("@/assets/images/logo.png"),
      },
      classObject: {
        grayBg: false,
      },
    };
  },
  computed: {
    active() {
      return this.$store.state.navActive;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleResize);
  },
  methods: {
    goPage: function (v) {
      this.$store.commit("SET_ACTIVE", v);
      if (v == 1) {
        this.classObject.grayBg = true;
      } else {
        this.classObject.grayBg = false;
      }
    },
    sendId(v) {
      this.$router.push({ name: "about", params: { id: v } });
    },
    routeChange() {
      this.$store.commit("SET_ACTIVE", 1);
    },
    handleScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop > 80) {
        this.classObject.grayBg = true;
      } else if (scrollTop < 80) {
        this.classObject.grayBg = false;
      }
    },
    toggleMenu: function () {
      this.miniActive = !this.miniActive;
    },
  },
};
</script>

<style scoped>
.header {
  width: 100%;
  position: fixed;
  top: 0;
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 100;
}
.header.grayBg {
  background-color: #f5f5f5;
  color: #333;
}
.header.grayBg li {
  color: #333;
}
.header.grayBg li.active {
  color: #3543b5;
}
.header ul {
  padding-left: 200px;
  padding-right: 100px;
}
.header ul li {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  letter-spacing: 2px;
  cursor: pointer;
  position: relative;
  -moz-user-select: none; /*火狐*/
  -webkit-user-select: none; /*webkit浏览器*/
  -ms-user-select: none; /*IE10*/
  -khtml-user-select: none; /*早期浏览器*/
  user-select: none;
}
.header ul li:hover::after {
  display: block;
  content: "";
  height: 4px;
  width: 80%;
  background-color: #12399c;
  border-radius: 10px;
  margin-top: 3px;
}
.header ul li::after {
  display: block;
  content: "";
  height: 4px;
  width: 0;
  background-color: #12399c;
  border-radius: 10px;
  margin-top: 3px;
  transition: width 0.3s ease-in;
}
.header ul li.active::after {
  width: 80%;
}
.header ul li.special {
  position: relative;
}
.header ul li.special .fun {
  position: absolute;
  top: -25rem;
  width: 100%;
  opacity: 0;
  background-color: #ffffff;
  border-radius: 1rem;
  color: rgba(51, 48, 63, 0.8);
  text-align: center;
  font-size: 0.9rem;
  overflow: hidden;
  transition: all 0.5s ease-in;
}
.header ul li.special:hover .fun {
  top: 2.5rem;
  opacity: 1;
}
.header ul li.special .fun div {
  line-height: 3rem;
}
.header ul li.special .fun div:hover {
  color: #373f83;
  font-weight: 600;
  background-color: #cfe0ff;
}

/* 移动端导航条 */

.mini-header {
  position: fixed;
  width: 100%;
  display: none;
  z-index: 10;
  background-color: rgba(47, 47, 48, 0.95);
  color: #ccc;
  position: fixed;
}
.mini-header .ac-header {
  display: flex;
  padding: 0.8rem 1rem;
}
.mini-header .ac-header .menu-flag {
  display: flex;
  height: 1.2rem;
  width: 1.2rem;
  position: relative;
  flex-direction: column;
  justify-content: space-evenly;
}
.mini-header .ac-header .menu-flag::after {
  display: block;
  content: "";
  width: 100%;
  height: 1px;
  background: white;
  transition: all 0.3s ease-in;
}
.mini-header .ac-header .menu-flag::before {
  display: block;
  content: "";
  width: 100%;
  height: 1px;
  background: white;
  transition: all 0.3s ease-in;
}
.mini-header .ac-header .logo-box {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.mini-header .ac-header .logo-box .logo {
  height: 1.2rem;
  width: 1.2rem;
}
.mini-header .ac-header .logo-box .logo img {
  width: 100%;
}
.mini-header ul {
  display: flex;
  flex-direction: column;
  padding: 0;
  height: 0;
  overflow: hidden;
  transition: max-height 0.3 ease-in;
}
.mini-header ul li {
  padding: 0.8rem;
  border-bottom: 1px solid #424245;
  font-size: 0.85rem;
}
.mini-header .ac-header.active .menu-flag {
  justify-content: center;
}
.mini-header .ac-header.active .menu-flag::after {
  transform: rotate(-45deg);
}
.mini-header .ac-header.active .menu-flag::before {
  transform: rotate(45deg);
}
.mini-header .ac-header.active ~ ul {
  height: auto;
  max-height: 400px;
  padding: 0 1rem 1rem;
}

/* PC端脚部 */
.footer {
  padding: 1rem;
  width: 100%;
  background-color: #202427;
  margin-top: 2rem;
  color: #7281ad;
  font-size: 0.9rem;
}
.footer span {
  margin-right: 1.5rem;
}

@media screen and (max-width: 1000px) {
  .header ul {
    padding-left: 0;
    padding-right: 0;
  }
}
@media screen and (max-width: 500px) {
  .header {
    display: none;
  }
  .mini-header {
    display: block;
  }
  .footer .container div {
    flex-direction: column;
  }
}
</style>
