<template>
  <div class="main">
    <div class="banner mb-3">
      <img :src="imgs.banner" alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计" />
    </div>
    <div class="container d-flex">
      <div class="content-box">
        <div class="content" v-for="(item, index) in updateMsg" :key="index">
          <div class="v-text">
            <span>版本号：{{ item.version }}</span>
            <span class="ml-3 text-muted">{{ item.setTime }}</span>
          </div>
          <div v-html="item.intro">
          </div>
          <!-- <div class="text-line">
            <div class="label">增加:</div>
            <div class="text">
              剧本可以设置隐藏状态了
              <span class="text-muted">（相当于商品下架）；</span>
            </div>
          </div>
          <div class="text-line">
            <div class="label">修复：</div>
            <div class="text">扫桌码创建的组局导致生成组局信息失败的bug；</div>
          </div> -->
         
        </div>
      </div>

      <div class="global-float">
        <!-- <div class="card c-article">
          <div class="card-title">
            <div class="point-t"></div>
            <div>热点文章</div>
          </div>
          <div class="card-body">
            <div class="article-c">
              <div class="d-flex align-items-center">
                <div class="wz-con">
                  <div class="wz-title">文章标题文章标题</div>
                  <div>文章文章文章文章文章文 章文章文章...</div>
                </div>
                <div class="wz-img"></div>
              </div>
              <div class="d-flex align-items-center">
                <div class="wz-con">
                  <div class="wz-title">文章标题文章标题</div>
                  <div>文章文章文章文章文章文 章文章文章...</div>
                </div>
                <div class="wz-img"></div>
              </div>
              <div class="d-flex align-items-center">
                <div class="wz-con">
                  <div class="wz-title">文章标题文章标题</div>
                  <div>文章文章文章文章文章文 章文章文章...</div>
                </div>
                <div class="wz-img"></div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="btn">查看更多</div>
          </div>
        </div> -->
        <router-link :to="{ path: '/about/13' }">
          <div class="card c-function">
            <div class="card-title">
              <div class="point-t"></div>
              <div>功能介绍</div>
            </div>
            <div class="card-body">
              <div class="func-c">
                <div class="d-flex flex-wrap">
                  <div class="item">简介</div>
                  <div class="item">剧本和目录</div>
                  <div class="item">会员和卡券</div>
                  <div class="item">预约和组局</div>
                  <div class="item">买单和价格</div>
                  <div class="item">卡券和抽奖活动</div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="btn">
                <router-link :to="{ path: '/about/13' }">
                  查看更多
                </router-link>
              </div>
            </div>
          </div>
        </router-link>
        <div class="card c-register">
          <div class="card-title">
            <div class="point-t"></div>
            <div>注册指引</div>
          </div>
          <div class="card-body">
            <div class="register-c">
              <div class="d-flex flex-wrap">
                <div class="item">
                  <router-link :to="{ name: 'settled' }">
                    店家注册
                  </router-link>
                </div>
                <div class="item">
                  <router-link :to="{ name: 'settled' }">
                    发行注册
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <router-link :to="{ path: '/about/14' }">
          <div class="card c-problem">
            <div class="card-title">
              <div class="point-t"></div>
              <div>常见问题</div>
            </div>
            <div class="card-body">
              <div class="problem-c">
                <div>
                  <div class="d-flex align-items-center">
                    <div class="serial-num">1</div>
                    <div class="item">如何上架剧本</div>
                  </div>
                  <div class="d-flex align-items-center">
                    <div class="serial-num">2</div>
                    <div class="item">如何添加店员</div>
                  </div>
                  <div class="d-flex align-items-center">
                    <div class="serial-num">3</div>
                    <div class="item">如何生成剧本海报</div>
                  </div>
                  <div class="d-flex align-items-center">
                    <div class="serial-num">4</div>
                    <div class="item">剧本目录自定义</div>
                  </div>
                  <div class="d-flex align-items-center">
                    <div class="serial-num">5</div>
                    <div class="item">剧本下架</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="btn">
                <router-link :to="{ path: '/about/14' }">
                  查看更多
                </router-link>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      imgs: {
        banner: require("@/assets/images/update-banner.png"),
      },
      updateMsg: []
    };
  },
  created() {
    this.getMsg()
  },
  methods: {
    getMsg() {
      this.$axios.get('/edition/list', {
        params: {
          type: 1,
          page: 1,
          limit: 10
        }
      }).then(res => {
        if (res.data.code == 0) {
          this.updateMsg = res.data.data
          console.log(this.updateMsg)
        } else {
          this.$message.error('请求失败')
        }
      })
    },
  },
};
</script>
<style scoped>
img {
  width: 100%;
}

.main .content-box {
  width: 70%;
}

.main .content {
  width: 100%;
  padding: 0 2rem;
  margin-bottom: 2rem;
}

.text-muted {
  color: rgba(0, 0, 0, 0.5);
}

.main .content .v-text {
  padding: 2rem 0;
}

.main .content .text-line {
  width: 100%;
  display: flex;
  font-size: 0.94rem;
  line-height: 2rem;
}

.main .content .text-line .label {
  display: inline-block;
  width: 8%;
}

.main .content .text-line .text {
  display: inline-block;
  width: 90%;
}

@media screen and (max-width: 500px) {
  .main .content {
    padding: 0 1rem;
  }

  .main .content .v-text {
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
  }

  .main .content .v-text .text-muted {
    text-align: right;
  }

  .main .content .text-line {
    flex-direction: column;
  }

  .main .content .text-line .label {
    width: 100%;
    font-weight: 600;
  }

  .main .content .text-line .text {
    font-size: 0.9rem;
  }
}
</style>