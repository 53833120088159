import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/pc/Home.vue'
import About from '../views/pc/About.vue'
import Robot from '../views/pc/Robot.vue'
import JoinUs from '../views/pc/JoinUs.vue'
import Settled from '../views/pc/Settled.vue'
import Update from '../views/pc/Update.vue'
import Articial from '../views/pc/Articial.vue'
import HeaderMenu from '../components/HeaderMenu.vue'
import Index from '../views/admin/index.vue'
import vProblem from '../views/admin/vProblem/vProblem.vue'
import Wxurl from '../views/admin/wxUrl/Wxurl.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: HeaderMenu,
    redirect: '/home',
    children: [
      {
        path: 'home',
        name: 'home',
        component: Home,
        meta: { title: '谋杀之谜秘书' }
      },
      {
        path: 'about/:id',
        name: 'about',
        component: About,
        meta: { title: '谋杀之谜秘书 - 关于我们（谋杀之谜 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计。谋杀之谜,谋杀之谜秘书,剧本,剧本杀,桌游,在线剧本,剧本库,剧本详情,免费剧本,开店小程序,剧本店,剧本网站,安全管理,剧本店铺）' }
      },
      {
        path: 'robot',
        name: 'robot',
        component: Robot,
        meta: { title: '谋杀之谜秘书 - 机器人小蜜（谋杀之谜 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计。谋杀之谜,谋杀之谜秘书,剧本,剧本杀,桌游,在线剧本,剧本库,剧本详情,免费剧本,开店小程序,剧本店,剧本网站,安全管理,剧本店铺）' }
      },
      {
        path: 'joinUs',
        name: 'joinUs',
        component: JoinUs,
        meta: { title: '谋杀之谜秘书 - 申请注册（谋杀之谜 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计。谋杀之谜,谋杀之谜秘书,剧本,剧本杀,桌游,在线剧本,剧本库,剧本详情,免费剧本,开店小程序,剧本店,剧本网站,安全管理,剧本店铺）' }
      },
      {
        path: 'settled',
        name: 'settled',
        component: Settled,
        meta: { title: '谋杀之谜秘书 - 注册指引（谋杀之谜 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计。谋杀之谜,谋杀之谜秘书,剧本,剧本杀,桌游,在线剧本,剧本库,剧本详情,免费剧本,开店小程序,剧本店,剧本网站,安全管理,剧本店铺）' }
      },
      {
        path: 'update',
        name: 'update',
        component: Update,
        meta: { title: '谋杀之谜秘书 - 更新日志（谋杀之谜 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计。谋杀之谜,谋杀之谜秘书,剧本,剧本杀,桌游,在线剧本,剧本库,剧本详情,免费剧本,开店小程序,剧本店,剧本网站,安全管理,剧本店铺）' }
      },
      {
        path: 'articial',
        name: 'articial',
        component: Articial,
        meta: { title: '谋杀之谜秘书 - 热门文章（谋杀之谜 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计。谋杀之谜,谋杀之谜秘书,剧本,剧本杀,桌游,在线剧本,剧本库,剧本详情,免费剧本,开店小程序,剧本店,剧本网站,安全管理,剧本店铺）' }
      }
    ]
  },
  {
    path: '/admin',
    component: Index,
    redirect: '/admin/vproblem',
    children: [
      {
        path: 'vproblem',
        name: 'vproblem',
        component: vProblem,
        meta: { title: '管理（谋杀之谜 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计）' }
      }, {
        path: 'wxurl',
        name: 'wxurl',
        component: Wxurl,
        meta: { title: '管理（谋杀之谜 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计）' }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


export default router
