<template>
  <div class="main">
    <div class="fixed-bg" v-if="pageId == 45"></div>
    <div class="banner mb-3">
      <img
        v-if="pageId == 13"
        :src="imgs.funBanner"
        alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计"
      />
      <img
        v-if="pageId == 14"
        :src="imgs.proBanner"
        alt="谋杀之谜秘书 - 剧本杀 - 开店经营小秘书、为剧本杀商家而设计"
      />
    </div>
    <div class="container">
      <div class="content">
        <div class="con-navL" :class="{ orange: pageId == 14 }">
          <div>
            <div
              class="nav-item"
              :class="{ active: activeId == item.id }"
              v-for="(item, index) in currentMenu"
              :key="index"
              @click="chooseNav(item)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="con-navR">
          <div ref="content" :innerHtml="content"></div>
        </div>
        <div class="global-float">
          <!-- <div class="card c-article">
            <div class="card-title">
              <div class="point-t"></div>
              <div>热点文章</div>
            </div>
            <div class="card-body">
              <div class="article-c">
                <div class="d-flex align-items-center">
                  <div class="wz-con">
                    <div class="wz-title">文章标题文章标题</div>
                    <div>文章文章文章文章文章文 章文章文章...</div>
                  </div>
                  <div class="wz-img"></div>
                </div>
                <div class="d-flex align-items-center">
                  <div class="wz-con">
                    <div class="wz-title">文章标题文章标题</div>
                    <div>文章文章文章文章文章文 章文章文章...</div>
                  </div>
                  <div class="wz-img"></div>
                </div>
                <div class="d-flex align-items-center">
                  <div class="wz-con">
                    <div class="wz-title">文章标题文章标题</div>
                    <div>文章文章文章文章文章文 章文章文章...</div>
                  </div>
                  <div class="wz-img"></div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="btn">查看更多</div>
            </div>
          </div> -->
          <router-link :to="{ path: '/about/13' }">
            <div class="card c-function" v-if="pageId == 14">
              <div class="card-title">
                <div class="point-t"></div>
                <div>功能介绍</div>
              </div>
              <div class="card-body">
                <div class="func-c">
                  <div class="d-flex flex-wrap">
                    <div class="item">简介</div>
                    <div class="item">剧本和目录</div>
                    <div class="item">会员和卡券</div>
                    <div class="item">预约和组局</div>
                    <div class="item">买单和价格</div>
                    <div class="item">卡券和抽奖活动</div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="btn">
                  <router-link :to="{ path: '/about/13' }">
                    查看更多
                  </router-link>
                </div>
              </div>
            </div>
          </router-link>
          <div class="card c-register">
            <div class="card-title">
              <div class="point-t"></div>
              <div>注册指引</div>
            </div>
            <div class="card-body">
              <div class="register-c">
                <div class="d-flex flex-wrap">
                  <div class="item">
                    <router-link :to="{ name: 'settled' }">
                      店家注册
                    </router-link>
                  </div>
                  <div class="item">
                    <router-link :to="{ name: 'settled' }">
                      商家注册
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <router-link :to="{ path: '/about/14' }">
            <div class="card c-problem" v-if="pageId == 13">
              <div class="card-title">
                <div class="point-t"></div>
                <div>常见问题</div>
              </div>
              <div class="card-body">
                <div class="problem-c">
                  <div>
                    <div class="d-flex align-items-center">
                      <div class="serial-num">1</div>
                      <div class="item">如何上架剧本</div>
                    </div>
                    <div class="d-flex align-items-center">
                      <div class="serial-num">2</div>
                      <div class="item">如何添加店员</div>
                    </div>
                    <div class="d-flex align-items-center">
                      <div class="serial-num">3</div>
                      <div class="item">如何生成剧本海报</div>
                    </div>
                    <div class="d-flex align-items-center">
                      <div class="serial-num">4</div>
                      <div class="item">剧本目录自定义</div>
                    </div>
                    <div class="d-flex align-items-center">
                      <div class="serial-num">5</div>
                      <div class="item">剧本下架</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="btn">
                  <router-link :to="{ path: '/about/14' }">
                    查看更多
                  </router-link>
                </div>
              </div>
            </div>
          </router-link>
          <router-link :to="{ name: 'update' }">
            <div class="card c-update">
              <div class="card-title">
                <div class="point-t"></div>
                <div>更新日志</div>
              </div>
              <div class="card-body">
                <div class="update-c">
                  <div>
                    <div class="version">版本号: 3.13.35</div>
                    <div class="v-time">2021.9.16-16:35</div>
                    <div class="v-detail">
                      新增：充值活动以及会员卡券活动...
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="btn">
                  <router-link :to="{ name: 'update' }"> 查看更多 </router-link>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Authorization
// Bearer eyJhbGciOiJIUzUxMiJ9.
// eyJzdWIiOiIxMzkwIiwiZXhwIjoyMjI0MzgxNDYxLCJpYXQiOjE2MTk1ODE0NjF9.
// wx9OQsUvYz1DEj1StIWZ40E4YMKFj5xZ7LEBy4_fQtFPwJZxALTPLZuyXJ_zKR338KKAoO3MXBMvg-ahdyc7Kw
export default {
  name: "Home",
  data() {
    return {
      imgs: {
        funBanner: require("@/assets/images/fun-banner.png"),
        proBanner: require("@/assets/images/pro-banner.png"),
      },
      activeId: 0,
      allMenu: [],
      currentMenu: [],
      pageId: 13,
      content: "",
    };
  },
  watch: {
    "$route.params.id": {
      handler(newName) {
        this.pageId = newName;
        this.switchMenu(newName);
      },
    },
  },
  mounted() {
    this.pageId = this.$route.params.id;
    this.loadMenu(this.pageId);
  },
  methods: {
    switchMenu(v) {
      this.currentMenu = this.allMenu.filter((item) => {
        return item.parentId == v;
      });
      this.activeId = this.currentMenu[0].id;
      this.$refs.content.innerHTML = this.currentMenu[0].content;
    },
    chooseNav(v) {
      this.activeId = v.id;
      this.$refs.content.innerHTML = v.content;
    },
    loadMenu(v) {
      this.$axios.get("/web/menu/list").then((c) => {
        if (c.status == 200) {
          this.allMenu = c.data.data;

          this.currentMenu = c.data.data.filter((item) => {
            return item.parentId == v;
          });
          console.log(v);

          this.activeId = this.currentMenu[0].id;
          this.$refs.content.innerHTML = this.currentMenu[0].content;
        }
      });
    },
  },
};
</script>
<style scoped>
.fixed-bg {
  width: 100%;
  background-color: rgba(47, 47, 48, 0.95);
  height: 60px;
}
img {
  width: 100%;
}
.main .content {
  width: 100%;
  display: flex;
}
.main .content .con-navL {
  width: 20%;
  /* max-height: 700px;
  overflow: hidden;
  overflow-y: scroll; */
}
.main .content .con-navL .nav-item {
  display: flex;
  font-size: 0.95rem;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  padding: 1.8rem 1rem;
  background-color: #f5f5f5;
  text-align: center;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}
.main .content .con-navL .nav-item.active {
  background-color: #ffd9e5;
  color: #962e4f;
}
.main .content .con-navL .nav-item.active::after {
  display: flex;
  content: "";
  position: absolute;
  width: 0.5rem;
  height: 100%;
  background-color: #ff719d;
  right: 0;
  top: 0;
}
.main .content .con-navL.orange .nav-item.active {
  background-color: #ffd7b7;
  color: #be440a;
}
.main .content .con-navL.orange .nav-item.active::after {
  background-color: #ffa479;
}
.main .content .con-navR {
  width: 60%;
  padding: 1rem 2rem;
}
.main .content .con-navR .title {
  width: 100%;
  font-weight: 600;
  text-align: center;
  font-size: 1.5rem;
}
.main .content .con-navR .author {
  width: 100%;
  text-align: right;
  color: #ccc;
}
.main .content .con-navR .article {
  width: 100%;
}
.main .content .con-navR .article .headline {
  width: 100%;
  font-size: 1.3rem;
  font-weight: 600;
  padding: 1rem;
}
.main .content .con-navR .article .text {
  width: 100%;
  font-size: 1.1rem;
  padding: 1rem;
  line-height: 2rem;
  padding-left: 3rem;
  color: rgba(0, 0, 0, 80%);
}
.main .content .con-navR iframe {
  width: 100%;
}

@media screen and (max-width: 500px) {
  .main .content {
    flex-direction: column;
  }
  .main .content .con-navL {
    width: 100%;
    overflow-y: hidden;
    overflow-x: scroll;
  }
  .main .content .con-navL > div {
    display: flex;
    overflow-y: hidden;
    flex-shrink: 0;
    min-width: max-content;
  }
  .main .content .con-navL .nav-item {
    padding: 1rem;
    width: auto;
    font-size: 1rem;
    font-weight: 500;
  }
  .main .content .con-navL .nav-item.active::after {
    display: none;
  }
  .main .content .con-navR {
    width: 100%;
    padding: 1rem 0.5rem;
  }
}
</style>
