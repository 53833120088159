<template>
  <div class="container">
    <header>
      <center>
        <h1 class="mb-3">谋杀之谜秘书<span class="sub"></span></h1>
        <div class="mt-3 mb-3">
          <select
            name="ClassA"
            id="ClassA"
            class="mr-3"
            v-model="classA"
            @change="getMenu()"
          >
            <option value="0">请选择菜单</option>
            <option
              v-for="(item, index) in OneMenu"
              :key="index"
              :value="item.id"
            >
              {{ item.name }}
            </option>
          </select>
          <select
            name="ClassB"
            id="ClassB"
            v-model="classB"
            @change="classBChange()"
          >
            <option value="0">请选择菜单</option>
            <option
              v-for="(item, index) in littleMenu"
              :key="index"
              :value="item.id"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
        <div class="w-100">
          <input v-model="token" type="text" placeholder="请输入token" />
        </div>
      </center>
    </header>
    <div id="edit"></div>
    <div class="footer mt-3">
      <div class="btn bg-blue" @click="submit">提交</div>
    </div>
    <div class="mask" @click="showPopModal" v-if="showPop">
      <div class="pop-inner">请先选择菜单</div>
    </div>
  </div>
</template>

<script>
// https://www.wangeditor.com/
import E from "wangeditor";

export default {
  name: "vProblem",
  computed: {},
  mounted() {
    this.loadEdit();
  },
  data() {
    return {
      token:"Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxMzkwIiwiZXhwIjoyMjI0MzgxNDYxLCJpYXQiOjE2MTk1ODE0NjF9.wx9OQsUvYz1DEj1StIWZ40E4YMKFj5xZ7LEBy4_fQtFPwJZxALTPLZuyXJ_zKR338KKAoO3MXBMvg-ahdyc7Kw",
      // token: "",
      menu: [],
      littleMenu: [],
      classA: 0,
      classB: 0,
      ATitem: {},
      markdown: "",
      showPop: false,
      OneMenu: [
        {
          id: 13,
          name: "功能介绍",
        },
        {
          id: 14,
          name: "常见问题",
        },
        // {
        //   id: 45,
        //   name: "热点文章",
        // },
      ],
    };
  },
  methods: {
    loadEdit: function () {
      let that = this;
      this.$axios.get("/web/menu/list").then((c) => {
        if (c.status == 200) {
          that.menu = c.data.data;
        }
      });
      this.markdown = new E("#edit");
      this.markdown.create();
      // editor.txt.html("<p>用 JS 设置的内容</p>"); // 重新设置编辑器内容
      // editor.txt.append("<p>追加的内容</p>");
      // this.markdown .txt.text();
    },
    getMenu: function () {
      this.littleMenu = this.menu.filter((item) => {
        return item.parentId == this.classA;
      });
    },
    classBChange: function () {
      let _item = this.menu.find((value) => value.id == this.classB);
      this.ATitem = _item;
      this.markdown.txt.html(_item.content); // 重新设置编辑器内容
    },
    submit: function () {
      if (this.classA == 0) {
        this.showPop = true;
        return;
      }
      if (this.classB == 0) {
        this.showPop = true;
        return;
      }
      let data = {
        id: this.classB,
        weight: 0,
        content: this.markdown.txt.html(),
        name: this.ATitem.name,
      };
      let headers = { Authorization: this.token };
      this.$axios
        .put("/web/menu?id=" + this.classB, data, { headers: headers })
        .then((c) => {
          if (c.data.code == 200) {
            alert("修改成功");
          } else {
            alert(c.data.message);
          }
        });
    },
    showPopModal: function () {
      this.showPop = !this.showPop;
    },
  },
};
</script>

<style scoped>
header {
  background: linear-gradient(45deg, #020031, #6d3353);
  box-shadow: inset 0 3px 7px rgb(0 0 0 / 20%),
    inset 0 -3px 7px rgb(0 0 0 / 20%);
  padding: 45px 0 35px;
  margin-bottom: 40px;
}
h1 {
  line-height: 1;
  font-size: 40px;
  color: #fff;
  margin-bottom: 15px;
  text-shadow: 0 2px 2px #333;
  position: relative;
  font-weight: 500;
}
p {
  color: #f1f1f1;
  font-size: 19px;
  line-height: 2;
  text-shadow: 0 1px 3px rgb(0 0 0 / 40%), 0 0 30px rgb(0 0 0 / 8%);
}
h1 .sub {
  position: absolute;
  font-size: 16px;
  font-weight: normal;
  top: 8px;
  margin-left: 9px;
}
select,
input {
  min-width: 150px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  display: inline-flex;
}
select:hover,
input:hover {
  border-color: #40a9ff;
  border-right-width: 1px !important;
}
.mask {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 2147483649;
}
.mask .pop-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50% -50%);
  background: rgba(255, 255, 255, 1);
  padding: 2rem 3rem;
  border-radius: 1rem;
}
</style>