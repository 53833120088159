import Vue from 'vue'
import Vuex from 'vuex'
import { getNavNum, setNavNum } from '@/utils/cookie'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    navActive: getNavNum() ? getNavNum() : 0
  },
  mutations: {
    SET_ACTIVE: (state, self) => {
      state.navActive = self
      setNavNum(self)
    }
  },
  actions: {
    SET_ACTIVE({ commit }, msg) {
      commit('SET_ACTIVE', msg)
    }
  },
  modules: {
  }
})
